import XClose from "@/icons/XClose";
import { Grid, IconButton, Tooltip, useTheme } from "@mui/material";
import ConfirmButton from "./ConfirmButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface Props {
    onApprove: () => void;
    onReject?: () => void;
    approveDisabled?: boolean;
    rejectDisabled?: boolean;
    withRejectBnt?: boolean;
    rejectTitle?: string;
    approveTitle?: string;
    justifyBtns?: string;
    approveIcon?: JSX.Element;
    approveIconColor?: "error" | "primary" | "secondary" | "info" | "success" | "warning";
    isGrey?: boolean;
}

const RejectApproveButtons = ({ onReject, onApprove, approveDisabled, rejectDisabled, withRejectBnt = true, rejectTitle, approveTitle, justifyBtns = 'flex-start', approveIcon, approveIconColor = 'success', isGrey }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const handleAction = async (action?: () => void) => {

        if (!action) return;
        try {
            setLoading(true);
            await action();
            setLoading(false);
        }
        catch (e) {
            setLoading(false);

        }
    }

    return (
        <Grid container columnGap={0.5} justifyContent={justifyBtns}>
            {withRejectBnt &&
                <Tooltip title={rejectTitle || t('form.buttons.reject')}>
                    <Grid item>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="error"
                            onClick={() => handleAction(onReject)}
                            disabled={rejectDisabled || loading}
                            sx={{
                                maxHeight: '24px',
                                maxWidth: '24px',
                                backgroundColor: theme.palette.error.light,
                                '&:hover': {
                                    backgroundColor: theme.palette.error.light,
                                },
                                '&.Mui-disabled': {
                                    opacity: 0.4,
                                    backgroundColor: theme.palette.error.light,
                                },
                            }}
                        >
                            <XClose color={theme.palette.error.main} sx={{ fontSize: '1rem' }} />
                        </IconButton >
                    </Grid>
                </Tooltip>
            }
            <Tooltip title={approveTitle || t('form.buttons.approve')}>
                <Grid item>
                    <ConfirmButton onClick={() => handleAction(onApprove)} disabled={approveDisabled || loading} icon={approveIcon} color={approveIconColor} isGrey={isGrey} />
                </Grid>
            </Tooltip>
        </Grid>
    );
}

export default RejectApproveButtons;