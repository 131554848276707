import { Button, ButtonProps, Stack } from "@mui/material";
import React, { ReactElement } from 'react';

interface ButtonWithIconProps extends ButtonProps {
    icon: ReactElement,
    buttonText?: string
}

const ButtonWithIcon = ({ icon, buttonText, ...props }: ButtonWithIconProps) => {
    return (
        <Button {...props}>
            <Stack alignItems='center' direction='row' columnGap={1}>
                {React.cloneElement(icon)}
                {buttonText && buttonText}
            </Stack>
        </Button>
    );
}

export default ButtonWithIcon;
