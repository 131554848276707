import { PaginateParams } from "@/types/pagination";
import { IntegrationDetailsType, OverviewSubTabsReponse } from "../emiFinance/emiFinanceTypes"

export interface EmiFinanceBankingStore {
    payments: {
        loadingPayments: boolean,
        count: number,
        list: Array<Payment>,
        error: string | null,
        pagination: PaginateParams,
    },

    balances: Array<IntegrationDetailsType>,
    tabs: Array<OverviewSubTabsReponse>,
    activeTab: string,
    loading: boolean
}

export interface Payment {
    amount: number;
    clientAccount: string;
    clientId: string;
    currencyCode: string;
    emi: string;
    externalId: string;
    id: number;
    integration: string;
    payeeAccountNumber: string;
    payeeAddress: string;
    payeeIban: string;
    payeeName: string;
    payeeSortCode: string;
    payerAccountNumber: string;
    payerAddress: string;
    payerIban: string;
    payerName: string;
    payerSortCode: string;
    paymentDetails: string;
    status: string;
    toAmount: number;
    toCurrencyCode: string;
    transactionDate: string;
    transactionType: string;
    walletId: string;
    walletUuid: string;
    wlpId: string;
    scannerUrl: string;
}


export interface IntegrationBalanceType {
    id: number,
    emi: string,
    uiType: string,
    integration: string,
    description: string,
    wlpId: string,
    currencyCode: string,
    currencyLogo: string,
    totalAmount: number,
    totalAmountEUR: number,
    intGroup: string,
    detailList: Array<IntegrationBalanceDetailsType>
}

export interface IntegrationBalanceDetailsType {
    integration: string,
    wlpId: string,
    cpNameCode: string,
    cpDescription: string,
    cpMeaning: string,
    currencyCode: string,
    amount: number,
    pkey: string,
    intGroup: string,
}

export enum TransactionType {
    PAYMENT = "PAYMENT",
    DEPOSIT = "DEPOSIT",
    TRADE = "TRADE",
    WITHDRAW = "WITHDRAW"
};

export interface PaymentsSplit {
    id: number;
    emi: string;
    integration: string;
    sumType: TransactionType;
    sumTypeDescription: string;
    currencyCode: string;
    currencyLogo: string;
    amount: number;
    totalAmount: number;
    amountEur: number;
    totalAmountEur: number;
    totalAmountEurPerIntegarion: number;
}

export interface BankingBreakdownType {
    id: number;
    emi: string;
    uiType: string;
    integration: string;
    intGroup: string;
    groupHeader: string;
    description: string;
    cpNameCode: string;
    cpDescription: string;
    cpMeaning: string;
    wlpId: string;
    currencyCode: string;
    currencyLogo: string;
    amount: number;
    amountEUR: number;
    totalAmount: number;
    totalAmountEUR: number;
    detailList: Array<BankingBreakdownDetailsType>;
}

interface BankingBreakdownDetailsType {
    amount: number;
    amountEur: number;
    amountTotal: number;
    amountTotalEur: number;
    cpDescription: string;
    cpMeaning: string;
    cpNameCode: string;
    currencyCode: string;
    currencyLogo: string;
    groupHeader: string;
    intGroup: string;
    integration: string;
    pkey: string;
    wlpId: string;
}

export interface MatchingDetail {
    accountId?: number;
    walletId?: string;
    paymentConfigId?: number;
    currencyCode?: string;
    amount?: number;
    internalRef?: string;
    paymentDetails?: string;
    walletIdFrom?: string;
    amountFrom?: number;
    walletIdTo?: string;
    amountTo?: number;
};

export interface MatchTransactionPayload {
    transactionId: number;
    externalId: string;
    matchingType: string;
    paymentConfigId?: number;
    details: Array<MatchingDetail>;
};
export interface MathingSystemAccount {
    walletId: string;
    sysAcctType: string;
    emi: string;
    currencyCode: string;
    balance: number;
    paymentConfigId: number;
    description: string;
}