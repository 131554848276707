import DialogTitle from '@/components/DialogTitle';
import { Dialog, DialogContent, Grid, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Payment, TransactionType } from "@/redux/emiFinanceBanking/emiFinanceBankingTypes";
import { convertDateFromEpoch, formatDateWithPattern } from '@/utils/date';
import AmountValueCell from '@/components/dataGrid/renderCell/AmountValueCell';

interface Props {
    payment: Payment,
    open: boolean,
    onClose: () => void,
    isCrypto: boolean
};

const CheckTransaction = ({ payment, open, onClose, isCrypto }: Props) => {

    const { t } = useTranslation();

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            id="payment-form"
            open={open}
            onClose={handleClose}
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '300px',
                    maxHeight: '550px',
                    maxWidth: '600px'
                }
            }}>
            <DialogTitle onClose={handleClose}>
                {t('form.fields.details')}
            </DialogTitle>

            <DialogContent>
                <Grid container direction='column' wrap='nowrap'>

                    <Grid item pl='20px' mb={1} mt={1}
                        sx={(theme) => ({
                            borderRadius: '10px',
                            border: `1px solid ${theme.palette.transparentBlack}`,
                            backgroundColor: theme.palette.transparentBlack
                        })} >
                        <List dense={true}
                            sx={{
                                listStyleType: 'disc',
                                pl: 2,
                                '& .MuiListItem-root': {
                                    display: 'list-item',
                                    pl: 0,
                                },
                            }}>
                            <ListItem>
                                <Typography>
                                    <strong>{t('form.fields.transferId')}:</strong> {payment?.externalId}
                                </Typography>
                            </ListItem>
                            {payment.transactionType !== TransactionType.TRADE &&
                                <ListItem>
                                    <Grid container alignItems='center'>
                                        <Typography pr='4px'>
                                            <strong>{t('form.fields.amount')}: </strong>
                                        </Typography>
                                        <AmountValueCell value={Math.abs(payment?.amount)} currency={payment?.currencyCode} withLogo />
                                    </Grid>
                                </ListItem>}
                            <ListItem>
                                <Typography>
                                    <strong>{t('form.fields.date')}:</strong> {formatDateWithPattern(convertDateFromEpoch(payment?.transactionDate), 'dd.MM.yyyy HH:mm:ss')}
                                </Typography>
                            </ListItem>
                            {!isCrypto &&
                                <ListItem>
                                    <Typography>
                                        <strong>{t('form.fields.name')}:</strong> {payment.transactionType === TransactionType.PAYMENT ? payment.payeeName : payment.payerName}
                                    </Typography>
                                </ListItem>
                            }
                            {(((payment.transactionType === TransactionType.PAYMENT && !payment.payeeIban) || (payment.transactionType === TransactionType.DEPOSIT && !payment.payerIban)) && !crypto) ?
                                <>
                                    <ListItem>
                                        <Typography sx={{ wordBreak: 'break-all' }}>
                                            <strong>{t('form.fields.sortCode')}:</strong> {payment.transactionType === TransactionType.PAYMENT ? payment.payeeSortCode : payment.payerSortCode}
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography sx={{ wordBreak: 'break-all' }}>
                                            <strong>{t('form.fields.accountNumber')}:</strong> {payment.transactionType === TransactionType.PAYMENT ? payment.payeeAccountNumber : payment.payerAccountNumber}
                                        </Typography>
                                    </ListItem>
                                </> : (isCrypto ?
                                    <>
                                        {payment.transactionType !== TransactionType.TRADE ?
                                            <ListItem>
                                                <Typography sx={{ wordBreak: 'break-all' }}>
                                                    <strong>{t('form.fields.address')}:</strong> {payment.transactionType === TransactionType.WITHDRAW ? payment.payeeAddress : payment.payerAddress}
                                                </Typography>
                                            </ListItem> :
                                            <>
                                                <ListItem>
                                                    <Grid container alignItems='center'>
                                                        <Typography pr='4px'>
                                                            <strong>{t('form.fields.from')}: </strong>
                                                        </Typography>
                                                        <AmountValueCell value={Math.abs(payment?.amount)} currency={payment?.currencyCode} withLogo />
                                                    </Grid>
                                                </ListItem>
                                                <ListItem>
                                                    <Grid container alignItems='center'>
                                                        <Typography pr='4px'>
                                                            <strong>{t('form.fields.to')}: </strong>
                                                        </Typography>
                                                        <AmountValueCell value={Math.abs(payment?.toAmount)} currency={payment?.toCurrencyCode} withLogo />
                                                    </Grid>
                                                </ListItem>
                                            </>
                                        }
                                    </> :
                                    <ListItem>
                                        <Typography sx={{ wordBreak: 'break-all' }}>
                                            <strong>{t('form.fields.account')}:</strong> {payment.transactionType === TransactionType.PAYMENT ? payment.payeeIban : payment.payerIban}
                                        </Typography>
                                    </ListItem>
                                )}
                            <ListItem>
                                <Typography sx={{ wordBreak: 'break-all' }}>
                                    <strong>{(isCrypto && payment.transactionType !== TransactionType.TRADE) ? t('form.fields.transactionHash') : t('form.fields.paymentDetails')}:</strong> {payment?.paymentDetails}
                                </Typography>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    );
}

export default CheckTransaction;