import { getCurrencySign, getIsFiat } from "@/utils/currency";
import { Typography, TypographyProps } from "@mui/material";
import { NumericFormat } from "react-number-format";

interface Props extends TypographyProps {
    amount?: number,
    currency?: string,
    positiveColor?: string,
    decimalScale?: number,
}
const SummaryNumber = ({ amount, currency, positiveColor, decimalScale = 2, ...others }: Props) => {

    if (amount === undefined || amount === null) return <>-</>;
    const isNegative = amount < 0;
    const curr = currency ? getCurrencySign(currency) : '';
    const _curr = currency ? (getIsFiat(currency) ? curr : `${curr} `) : curr;

    return (
        isNegative ?
            <Typography variant='body1' color='error' {...others}>
                <NumericFormat
                    displayType={'text'}
                    fixedDecimalScale={true}
                    decimalScale={decimalScale}
                    thousandsGroupStyle='thousand'
                    thousandSeparator={true}
                    value={Math.abs(amount)}
                    prefix={`${curr}`} />
            </Typography> :
            <Typography variant='body1' {...others} color={positiveColor ? positiveColor : 'success'}>
                <NumericFormat
                    displayType={'text'}
                    fixedDecimalScale={true}
                    decimalScale={decimalScale}
                    thousandsGroupStyle='thousand'
                    thousandSeparator={true}
                    value={amount}
                    prefix={`${_curr}`}
                />
            </Typography>
    );
}

export default SummaryNumber;
