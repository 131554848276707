import { Chip, Grid } from '@mui/material';

export interface ChipBtn {
    label: string,
    code: string,
    onClick: () => void
}

interface Props {
    btnList: ChipBtn[],
    active: string
}

const ChipButtonGroup = ({ btnList, active }: Props) => {

    const btns = btnList.filter(p => !!p?.code) || [];

    if (btns.length === 0) return <></>;

    return (
        <Grid container>
            {btnList.map(item =>
                <Chip key={item.code}
                    label={item.label}
                    clickable
                    onClick={() => { item.onClick() }}
                    sx={theme => ({
                        mr: '6px', height: '24px',
                        backgroundColor: item.code === active ? theme.palette.primary.main : theme.palette.transparentGreen,
                        color: item.code === active ? theme.palette.primary.light : theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: item.code === active ? theme.palette.primary.dark : theme.palette.disabledGreen,
                            color: item.code === active ? theme.palette.primary.light : theme.palette.primary.main
                        }
                    })}
                />)}
        </Grid>
    );
};

export default ChipButtonGroup;