import AmountValueCell from "@/components/dataGrid/renderCell/AmountValueCell";
import { ListItem, Typography, Grid } from "@mui/material";

interface DetailItemProps {
    label: string;
    value: string | number;
    isAmount?: boolean;
    currency?: string;
}

const ListDetailItem = ({ label, value, isAmount = false, currency }: DetailItemProps) => {
    return (
        <ListItem>
            {isAmount && currency ? (
                <Grid container>
                    <Typography pr="4px">
                        <strong>{label}:</strong>
                    </Typography>
                    <AmountValueCell value={Number(value)} currency={currency} />
                </Grid>
            ) : (
                <Typography sx={{ wordBreak: "break-all" }}>
                    <strong>{label}:</strong> {value}
                </Typography>
            )}
        </ListItem>
    );
};

export default ListDetailItem;