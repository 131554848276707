import CustomDataGrid, { CustomDataGridRef } from "@/components/dataGrid/CustomDataGrid";
import { Divider, Grid, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { UnassignedPaymentsTabsEnum } from "./UnassignedPayments";
import { Payment, TransactionType } from "@/redux/emiFinanceBanking/emiFinanceBankingTypes";
import { PaginateParams } from "@/types/pagination";
import { dispatch, useSelector } from "@/store/store";
import { getBankingPaymentsList, paginationParamsInit, setCount, setPayments } from "@/redux/emiFinanceBanking/emiFinanceBankingSlice";
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { t } from '@/utils/translate';
import DateCell from "@/components/dataGrid/renderCell/DateCell";
import StatusActionsCell from "@/components/dataGrid/renderCell/StatusActionsCell";
import FlagReport from "@/icons/FlagReport";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import MatchTransactionForm from "../components/MatchTransactionForm";
import { RowActionType } from "@/types/rowActions";
import { getSystemIntegrationBalancing } from "@/redux/emiFinanceSystem/emiFinanceSystemSlice";
import TypographyWithEllipsis from "@/components/TypographyWithEllipsis";
import CheckTransaction from "../components/CheckTransaction";
import EyeView from "@/icons/EyeView";
import TransactionHashCell from "@/components/dataGrid/renderCell/TransactionHashCell";
import AmountWithLowerValueCell from "@/components/dataGrid/renderCell/AmountWithLowerValueCell";


export const getColumns = (actionItems: Array<RowActionType<Payment>>, handleMenuClick: (event: React.MouseEvent<HTMLElement>) => void, handleApprove: (payment: Payment) => void) => {
    const columns: GridColDef[] = [
        {
            field: 'transactionDate',
            headerName: t('form.fields.date'),
            flex: 1.2,
            renderCell: (params: GridRenderCellParams) => DateCell(params),
        },
        {
            field: 'amount',
            headerName: t('form.fields.amount'),
            flex: 1.2,
            renderCell: (params: GridRenderCellParams) => AmountWithLowerValueCell({ value: Math.abs(params.value), currency: params.row.currencyCode, withLogo: true }),
        },
        {
            field: 'paymentDetails',
            headerName: t('form.fields.paymentDetails'),
            flex: 1.7,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <TypographyWithEllipsis>
                        {params.value}
                    </TypographyWithEllipsis>
                );
            }
        },
        {
            field: 'transactionType',
            headerName: t('form.fields.user'),
            flex: 2.1,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                {params.row.transactionType === TransactionType.DEPOSIT ? params.row.payerName : params.row.payeeName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TypographyWithEllipsis variant="body2" color='text.secondary'>
                                {params.row.transactionType === TransactionType.DEPOSIT ? params.row.payerIban : params.row.payeeIban}
                            </TypographyWithEllipsis>
                        </Grid>
                    </Grid>);
            }
        },
        {
            field: 'externalId',
            headerName: t('form.fields.externalReference'),
            flex: 1.7,
            renderCell: (params: GridRenderCellParams) => TransactionHashCell(params),
        },

        {
            field: 'status',
            headerName: t('form.fields.actions'),
            flex: 1.4,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                const withActions = params.row.status === UnassignedPaymentsTabsEnum.UNMATCHED;
                return (
                    <StatusActionsCell params={params} withActions={withActions} withRejectBnt={false} onApprove={() => handleApprove(params.row)} approveTitle={t('form.buttons.match')} />
                );
            }
        },
        {
            field: 'walletId',
            headerName: t('form.fields.walletId'),
            flex: 1.1,
        },
        {
            field: "actions",
            type: "actions",
            headerName: t("form.fields.actions"),
            headerAlign: "center",
            align: "center",
            flex: 1,
            sortable: false,
            getActions: (params: GridRowParams) =>
                actionItems.map((item) => {
                    const icon: JSX.Element =
                        item.key === "flag" &&
                            params.row.flagged === true &&
                            item.iconOpposite
                            ? item.iconOpposite
                            : item.icon;

                    return (
                        <GridActionsCellItem
                            icon={icon}
                            disabled={item.disabled}
                            onClick={(event) => {
                                if (item.key === "menu") {
                                    handleMenuClick(event);
                                } else {
                                    item.onClick(params.row);
                                }
                            }}
                            label=""
                        />
                    );
                }),
        },
    ];
    return columns;
};

interface Props {
    type: TransactionType,
    activeType: UnassignedPaymentsTabsEnum,
    integration: string,
    walletUuid: string
}

const UnassignedPaymentsGrid = ({ type, activeType, integration, walletUuid }: Props) => {

    const { payments: { loadingPayments, list, count, error, pagination } } = useSelector((state) => state.emiFinanceBanking);

    // used for update the data in the settlement screen
    const { activeTab } = useSelector((state) => state.emiFinanceSystem);

    const theme = useTheme();
    const ref = useRef<CustomDataGridRef>(null);

    const [payment, setPayment] = useState<Payment | null>(null);

    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [openMatchForm, setOpenMatchForm] = useState(false);
    const [openCheck, setOpenCheck] = useState(false);

    const filter = activeType !== UnassignedPaymentsTabsEnum.ALL ?
        `transactionType=${type};status=${activeType === UnassignedPaymentsTabsEnum.UNMATCHED ? `${UnassignedPaymentsTabsEnum.PENDING},${UnassignedPaymentsTabsEnum.UNMATCHED}` : activeType};walletUuid=${walletUuid}` :
        `transactionType=${type};walletUuid=${walletUuid}`;

    const paymentsPagination = { ...pagination, filter: filter };
    const paymentsPaginationParamsInit = { ...paginationParamsInit, filter: filter };

    const fetch = async (searchParams: PaginateParams) => {
        await dispatch(getBankingPaymentsList(integration, searchParams))
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const handleMatch = (payment: Payment) => {
        setPayment(payment);
        setOpenMatchForm(true);
    };

    const handleCheck = (payment: Payment) => {
        setPayment(payment);
        setOpenCheck(true);
    };

    // update data in settlement form
    const fetchData = useCallback(() => {
        if (activeTab) {
            dispatch(getSystemIntegrationBalancing(activeTab));
        }
    }, [activeTab]);

    const columns = useMemo(() => {
        const actionItems = [
            {
                key: "flag",
                icon: <FlagReport />,
                iconOpposite: <FlagReport color="red" />,
                disabled: false,
                onClick: (row: Payment) => { },
            },
            {
                key: "eye",
                icon: <EyeView />,
                onClick: (row: Payment) => { handleCheck(row) },
            },
            // {
            //     key: "menu",
            //     icon: <MenuHorizontal />,
            //     disabled: !checkWritePermission(permissions, PermissionType.UNASSIGNED_PAYMENTS),
            //     onClick: (row: Payment) => { },
            // },
        ];
        return getColumns(actionItems, handleMenuClick, handleMatch);
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setPayments([]));
            dispatch(setCount(0));
        };
    }, [type]);

    return (
        <Grid item xs={12} mt='20px'>
            {payment &&
                <MatchTransactionForm
                    payment={payment}
                    onComplete={() => {
                        setOpenMatchForm(false);
                        ref?.current?.reloadData && ref?.current?.reloadData();
                        activeTab && fetchData();
                    }}
                    open={openMatchForm}
                    integration={integration}
                    onClose={() => setOpenMatchForm(false)}
                    isCrypto={false}
                />
            }
            {payment &&
                <CheckTransaction
                    payment={payment}
                    open={openCheck}
                    onClose={() => setOpenCheck(false)}
                    isCrypto={false}
                />
            }
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem>{t('form.buttons.flagTransaction')}</MenuItem>
                <MenuItem>{t('form.buttons.label')}</MenuItem>
                <Divider />
                <MenuItem sx={{ color: theme.palette.error.main }}>{t('form.buttons.freeze')}</MenuItem>
                <MenuItem sx={{ color: theme.palette.blue }}>{t('form.buttons.block')}</MenuItem>
            </Menu>
            <CustomDataGrid
                ref={ref}
                rows={list}
                getRowId={(row) => row.id}
                rowCount={count}
                withRefreshBtn={true}
                fetch={fetch}
                loading={loadingPayments}
                paginationParams={paymentsPagination}
                paginationParamsInit={paymentsPaginationParamsInit}
                columns={columns}
                loadingError={error}
                withSearch={true}
                searchFilterName="anyMatch"
                refreshFirstOnLoad={true}
            />
        </Grid>
    );
}

export default UnassignedPaymentsGrid;