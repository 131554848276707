import { lazy } from 'react';
import AuthGuard from '@/utils/routeGuard/AuthGuard';
import MainLayout from '@/layout/MainLayout';
import Loadable from 'components/Loadable';
import { PermissionType } from '@/redux/user/types';
import PermissionGuard from '@/utils/routeGuard/PermissionGuard';
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from '@/pages/maintenance/ErrorFallback';
import PageInDevelopment from '@/pages/maintenance/PageInDevelopment';
import PaymentsConfig from '@/pages/emiBilling/financialConfigurations/paymentsConfig/PaymentsConfig';

const Dashboard = Loadable(lazy(() => import('@/pages/dashboard/Dashboard')));
const Users = Loadable(lazy(() => import('pages/users/Users')));
const UsersDetails = Loadable(lazy(() => import('pages/userDetails/UsersDetails')));
const Accounts = Loadable(lazy(() => import('pages/accounts/Accounts')));
const AccountDetails = Loadable(lazy(() => import('pages/accountDetails/AccountDetails')));
const Transfers = Loadable(lazy(() => import('pages/transfers/Transfers')));
const TransferDetails = Loadable(lazy(() => import('pages/transferDetails/TransferDetails')));
const Cards = Loadable(lazy(() => import('pages/cards/Cards')));
const CryptoWallets = Loadable(lazy(() => import('@/pages/crypto/CryptoWallets')));
const CryptoTrades = Loadable(lazy(() => import('@/pages/crypto/CryptoTrades')));
const EarnDetails = Loadable(lazy(() => import('pages/earnDetails/EarnDetails')));
const EmiFinance = Loadable(lazy(() => import('pages/emiFinance/EmiFinance')));
const Compliance = Loadable(lazy(() => import('pages/compliance/Compliance')));
const EmiBilling = Loadable(lazy(() => import('@/pages/emiBilling/EmiBilling')));
const SuspiciousActivityReportDetails = Loadable(lazy(() => import('pages/compliance/components/SuspiciousActivityReportDetails')));
const ReferralPayments = Loadable(lazy(() => import('pages/userDetails/userReferals/ReferralPayments')));
const Platforms = Loadable(lazy(() => import('pages/platforms/Platforms')));
const PlatformDetails = Loadable(lazy(() => import('pages/platformDetails/PlatformDetails')));
const PlatformInvoices = Loadable(lazy(() => import('@/pages/emiBilling/platformInvoices/PlatformInvoices')));
const FeesBreakdown = Loadable(lazy(() => import('pages/emiFinance/EmiFinanceFees/FeesBreakdown')));
const UnassignedPayments = Loadable(lazy(() => import('pages/emiFinance/EmiFinanceBanking/UnassignedPayments')));
const BankCardDetails = Loadable(lazy(() => import('pages/bankCardDetails/BankCardDetails')));
const BalanceBreakdown = Loadable(lazy(() => import('pages/emiFinance/BalanceBreakdown')));
const CardsBalancesBreakdown = Loadable(lazy(() => import('pages/emiFinance/EmiFinanceCards/CardsBalancesBreakdown')));
const IntergrationTransfersBreakdown = Loadable(lazy(() => import('@/pages/emiFinance/EmiFinanceCards/IntergrationTransfersBreakdown')));
const BankingBreakdown = Loadable(lazy(() => import('pages/emiFinance/EmiFinanceBanking/BankingBreakdown')));
const CryptoExchangeBreakdown = Loadable(lazy(() => import('@/pages/emiFinance/EmiFinanceCrypto/breakdowns/CryptoExchangeBreakdown')));
const CryptoFireblocksBreakdown = Loadable(lazy(() => import('@/pages/emiFinance/EmiFinanceCrypto/breakdowns/CryptoFireblocksBreakdown')));
const CryptoCustodialBreakdown = Loadable(lazy(() => import('@/pages/emiFinance/EmiFinanceCrypto/breakdowns/CryptoCustodialBreakdown')));
const CryptoUserBalancesBreakdown = Loadable(lazy(() => import('@/pages/emiFinance/EmiFinanceCrypto/breakdowns/CryptoUserBalancesBreakdown')));
const CryptoUserBalancesCcyBreakdown = Loadable(lazy(() => import('@/pages/emiFinance/EmiFinanceCrypto/breakdowns/CryptoUserBalancesCcyBreakdown')));
const PLIntegrationBreakdown = Loadable(lazy(() => import('@/pages/emiFinance/EmiFinaceProfitLoss/PLIntegrationBreakdown')));
const PLCurrencyBreakdown = Loadable(lazy(() => import('@/pages/emiFinance/EmiFinaceProfitLoss/PLCurrencyBreakdown')));
const UnassignedPaymentsCrypto = Loadable(lazy(() => import('@/pages/emiFinance/EmiFinanceCrypto/UnassignedPaymentsCrypto')));
const EarnLiabilitiesBreakdown = Loadable(lazy(() => import('pages/emiFinance/EmiFinanceEarn/EarnLiabilitiesBreakdown')));
const EarnVaultsDetails = Loadable(lazy(() => import('pages/emiFinance/EmiFinanceEarn/EarnVaultsDetails')));
const Earn = Loadable(lazy(() => import('pages/earn/Earn')));
const EarnRepaymentsDetails = Loadable(lazy(() => import('@/pages/earn/EarnRepaymentsDetails')));
const EarnDepositDetails = Loadable(lazy(() => import('@/pages/earn/EarnDepositDetails')));
const EarnVaultPlanDepositDetails = Loadable(lazy(() => import('@/pages/earn/EarnVaultPlanDepositDetails')));
const EarnInterestDetails = Loadable(lazy(() => import('@/pages/earn/EarnInterestDetails')));
const EarnInterestDynamicDetails = Loadable(lazy(() => import('@/pages/earn/EarnInterestDynamicDetails')));
const EarnInterestRepaymentsDetails = Loadable(lazy(() => import('@/pages/earn/EarnInterestRepaymentsDetails')));
const EarnConfigurationDetails = Loadable(lazy(() => import('@/pages/earn/EarnConfigurationDetails')));
const Support = Loadable(lazy(() => import('pages/support/Support')));
const Tokens = Loadable(lazy(() => import('pages/tokens/Tokens')));
const TokenDetails = Loadable(lazy(() => import('pages/tokens/TokenDetails')));
const TokenFileDetails = Loadable(lazy(() => import('pages/tokens/components/finances/TokensIssusanceFileDetails')));
const EmiSettings = Loadable(lazy(() => import('pages/emiSettings/Settings')));
const EmiUserGroup = Loadable(lazy(() => import('@/pages/userGroupDetails/emiUserGroup/EmiUserGroup')));
const EmiUserPlan = Loadable(lazy(() => import('@/pages/userPlanDetails/emiUserPlan/EmiUserPlan')));
const DefiWalletDetails = Loadable(lazy(() => import('@/pages/defiWallets/DefiWalletDetails')));
const Reports = Loadable(lazy(() => import('@/pages/reports/Reports')));
const InvoiceDocument = Loadable(lazy(() => import('pages/emiBilling/invoiceDocument/InvoiceDocument')));
const PayoutDocument = Loadable(lazy(() => import('pages/emiBilling/payout/PayoutDocument')));
const FileUploads = Loadable(lazy(() => import('pages/emiFinance/EmiFinanceBanking/fileUploads/FileUploads')));
const Merchants = Loadable(lazy(() => import('pages/merchants/Merchants')));
const MerchantDetails = Loadable(lazy(() => import('pages/merchants/merchantDetails/MerchantDetails')));

const ClientInvoices = Loadable(lazy(() => import('pages/clientInvoices/ClientInvoices')));
const SystemAccounts = Loadable(lazy(() => import('@/pages/system/SystemAccounts')));
const SystemTransfers = Loadable(lazy(() => import('@/pages/system/SystemTransfers')));
const SystemAccountDetails = Loadable(lazy(() => import('@/pages/system/SystemAccountDetails')));
const SystemTransferDetails = Loadable(lazy(() => import('@/pages/system/SystemTransferDetails')));

const NotPermitted = Loadable(lazy(() => import('pages/maintenance/NotPermitted')));
const RouteNotFound = Loadable(lazy(() => import('pages/maintenance/RouteNotFound')));

const EmiRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <ErrorBoundary
                fallback={<ErrorFallback error />}   >
                <MainLayout />
            </ErrorBoundary>
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/users/details/:id/:tab',
            element:
                <PermissionGuard permission={PermissionType.USERS}>
                    <UsersDetails />
                </PermissionGuard>
        },
        {
            path: '/users/details/:id/referrals/payments/:refId',
            element:
                <PermissionGuard permission={PermissionType.USERS}>
                    <ReferralPayments />
                </PermissionGuard>
        },
        {
            path: '/users/:tab',
            element:
                <PermissionGuard permission={PermissionType.USERS}>
                    <Users />
                </PermissionGuard>
        },
        {
            path: '/accounts/details/:id',
            element:
                <PermissionGuard permission={PermissionType.ACCOUNTS}>
                    <AccountDetails />
                </PermissionGuard>
        },
        {
            path: '/accounts/:tab',
            element:
                <PermissionGuard permission={PermissionType.ACCOUNTS}>
                    <Accounts />
                </PermissionGuard>
        },
        {
            path: '/defi-wallets/details/:userId/:address',
            element:
                <PermissionGuard permission={PermissionType.DEFI_WALLETS}>
                    <DefiWalletDetails />
                </PermissionGuard>
        },
        {
            path: '/cards/details/:id',
            element:
                <PermissionGuard permission={PermissionType.CARDS}>
                    <BankCardDetails />
                </PermissionGuard>
        },
        {
            path: '/settings/:tab',
            element:
                <PermissionGuard userPermissions={[PermissionType.GENERAL_SETTINGS, PermissionType.MAINTENANCE, PermissionType.ROLES, PermissionType.SYSTEM_USERS]}>
                    <EmiSettings />
                </PermissionGuard>

        },
        {
            path: '/finances/:tab',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <EmiFinance />
                </PermissionGuard>
        },
        {
            path: '/finances/system/breakdown/:integration',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <BalanceBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/banking/breakdown/:integration',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <BankingBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/fees/breakdown/:feeCategory',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <FeesBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/banking/payments/:integration/:type',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <UnassignedPayments />
                </PermissionGuard>
        },
        {
            path: '/finances/banking/file-uploads/:provider',
            element:
                <PermissionGuard userPermissions={[PermissionType.FINANCE, PermissionType.FILE_UPLOAD]}>
                    <FileUploads />
                </PermissionGuard>
        },
        {
            path: '/finances/crypto/unassigned-payments/:type',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <UnassignedPaymentsCrypto />
                </PermissionGuard>
        },
        {
            path: '/finances/cards/balances/:integration',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <CardsBalancesBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/cards/integration/transfers/:integration',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <IntergrationTransfersBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/crypto/exchange/breakdown/:integration',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <CryptoExchangeBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/crypto/user/balances/breakdown',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <CryptoUserBalancesBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/crypto/user/balances/breakdown/:wlpId',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <CryptoUserBalancesCcyBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/profit-loss/breakdown/:integration',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <PLIntegrationBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/profit-loss/ccy/breakdown/:currencyCode',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <PLCurrencyBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/crypto/fireblocks/breakdown',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <CryptoFireblocksBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/crypto/custodial/breakdown/:integration',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <CryptoCustodialBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/earn/liabilities/breakdown/:transactionType',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <EarnLiabilitiesBreakdown />
                </PermissionGuard>
        },
        {
            path: '/finances/earn/vaults/details/:proc',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <EarnVaultsDetails />
                </PermissionGuard>
        },
        {
            path: '/transfers/:tab',
            element:
                <PermissionGuard permission={PermissionType.DOCUMENTS}>
                    <Transfers />
                </PermissionGuard>
        },
        {
            path: '/transfers/details/:id',
            element:
                <PermissionGuard permission={PermissionType.DOCUMENTS}>
                    <TransferDetails />
                </PermissionGuard>
        },
        {
            path: '/cards/:tab',
            element:
                <PermissionGuard permission={PermissionType.CARDS}>
                    <Cards />
                </PermissionGuard>
        },
        {
            path: '/wallets',
            element:
                <PermissionGuard permission={PermissionType.ACCOUNTS}>
                    <CryptoWallets />
                </PermissionGuard>
        },
        {
            path: '/trades',
            element:
                <PermissionGuard permission={PermissionType.DOCUMENTS}>
                    <CryptoTrades />
                </PermissionGuard>
        },
        {
            path: '/earn/details/:id/:uId',
            element:
                <PermissionGuard permission={PermissionType.ACCOUNTS}>
                    <EarnDetails />
                </PermissionGuard>
        },
        {
            path: '/compliance/:tab',
            element:
                <PermissionGuard permission={PermissionType.COMPLIANCE}>
                    <Compliance />
                </PermissionGuard>
        },
        {
            path: '/billing/:tab',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <EmiBilling />
                </PermissionGuard>
        },
        {
            path: '/billing/platform/invoices/:wlpId',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <PlatformInvoices />
                </PermissionGuard>
        },
        {
            path: '/invoice/details/:id',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <InvoiceDocument />
                </PermissionGuard>
        },
        {
            path: '/payout/details/:id',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <PayoutDocument />
                </PermissionGuard>
        },
        {
            path: '/sar/:id',
            element:
                <PermissionGuard permission={PermissionType.COMPLIANCE}>
                    <SuspiciousActivityReportDetails />
                </PermissionGuard>
        },
        {
            path: '/create/sar',
            element:
                <PermissionGuard permission={PermissionType.COMPLIANCE}>
                    <SuspiciousActivityReportDetails />
                </PermissionGuard>
        },
        {
            path: '/platforms/:tab',
            element:
                <PermissionGuard permission={PermissionType.PLATFORMS}>
                    <Platforms />
                </PermissionGuard>
        },
        {
            path: '/platform/details/:wlpId/:tab',
            element:
                <PermissionGuard permission={PermissionType.USERS}>
                    <PlatformDetails />
                </PermissionGuard>
        },
        {
            path: '/platform/payments-config/:wlpId',
            element:
                <PermissionGuard permission={PermissionType.FINANCE}>
                    <PaymentsConfig />
                </PermissionGuard>
        },
        {
            path: '/merchants/:tab',
            element:
                <PermissionGuard permission={PermissionType.MERCHANT_STORES}>
                    <Merchants />
                </PermissionGuard>
        },
        {
            path: '/merchants/store/details/:id',
            element:
                <PermissionGuard permission={PermissionType.MERCHANT_STORES}>
                    <MerchantDetails />
                </PermissionGuard>
        },
        {
            path: '/support',
            element:
                <PermissionGuard permission={PermissionType.SUPPORT}>
                    <Support />
                </PermissionGuard>
        },
        {
            path: '/analytics',
            element: <PageInDevelopment />
        },
        {
            path: '/configuration/:tab',
            element: <PageInDevelopment />
        },
        {
            path: '/earn/:tab',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <Earn />
            </PermissionGuard>
        },
        {
            path: '/earn/deposits/:proc',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <EarnDepositDetails />
            </PermissionGuard>
        },
        {
            path: '/earn/deposits/:proc/:accountId',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <EarnVaultPlanDepositDetails />
            </PermissionGuard>
        },
        {
            path: '/earn/interest/:proc',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <EarnInterestDetails />
            </PermissionGuard>
        },
        {
            path: '/earn/interest/dynamic/:proc',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <EarnInterestDynamicDetails />
            </PermissionGuard>
        },
        {
            path: '/earn/repayments/:proc',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <EarnRepaymentsDetails />
            </PermissionGuard>
        },
        {
            path: '/earn/payment/details/:proc',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <EarnInterestRepaymentsDetails />
            </PermissionGuard>
        },
        {
            path: '/earn/configuration/:proc',
            element: <PermissionGuard permission={PermissionType.EARN}>
                <EarnConfigurationDetails />
            </PermissionGuard>
        },
        {
            path: '/tokens/:tab',
            element: <PermissionGuard permission={PermissionType.TOKENS}>
                <Tokens />
            </PermissionGuard>
        },
        {
            path: '/tokens/details/:wlpId/:symbol',
            element: <PermissionGuard permission={PermissionType.TOKENS}>
                <TokenDetails />
            </PermissionGuard>
        },
        {
            path: '/user-group/:wlpId/:tab',
            element: <PermissionGuard permission={PermissionType.USER_GROUPS}>
                <EmiUserGroup />
            </PermissionGuard>
        },
        {
            path: '/user-plan/:wlpId/:tab',
            element: <PermissionGuard permission={PermissionType.USER_LEVEL}>
                <EmiUserPlan />
            </PermissionGuard>
        },
        {
            path: '/tokens/issuance/file/:fileId',
            element: <PermissionGuard permission={PermissionType.TOKENS}>
                <TokenFileDetails />
            </PermissionGuard>
        },
        {
            path: '/reports/:tab',
            element: <PermissionGuard permission={PermissionType.REPORTS}>
                <Reports />
            </PermissionGuard>
        },
        {
            path: '/invoices',
            element: <PermissionGuard permission={PermissionType.INVOICES}>
                <ClientInvoices />
            </PermissionGuard>
        },
        {
            path: '/system/accounts/:tab',
            element:
                <PermissionGuard permission={PermissionType.SYSTEM}>
                    <SystemAccounts />
                </PermissionGuard>
        },
        {
            path: '/system/accounts/details/:id',
            element:
                <PermissionGuard permission={PermissionType.SYSTEM}>
                    <SystemAccountDetails />
                </PermissionGuard>
        },
        {
            path: '/system/transfers',
            element:
                <PermissionGuard permission={PermissionType.SYSTEM}>
                    <SystemTransfers />
                </PermissionGuard>
        },
        {
            path: '/system/transfers/details/:id',
            element:
                <PermissionGuard permission={PermissionType.SYSTEM}>
                    <SystemTransferDetails />
                </PermissionGuard>
        },
        {
            path: '/not-permitted',
            element: <NotPermitted />
        },
        {
            path: '*',
            element: <RouteNotFound />
        },
    ]
};

export default EmiRoutes;
