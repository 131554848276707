import { setClean, setLoadingTime } from "@/redux/dialog/dialogSlice";
import { dispatch, useSelector } from "@/store/store";
import { Box, Dialog, DialogContent, DialogProps, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import DialogTitle from "./DialogTitle";
import { useTranslation } from "react-i18next";
import { styled, Theme, useTheme } from '@mui/material/styles';
import Checkmark from "@/icons/Checkmark";
import XClose from "@/icons/XClose";
import { useEffect } from 'react';
import LoaderCircular from "./LoaderCircular";

interface StatusProps {
    theme: Theme;
    success: boolean;
}

const Status = styled('div', { shouldForwardProp: (prop) => prop !== 'success' })(
    ({ theme, success }: StatusProps) => ({
        height: '70px',
        width: '70px',
        borderRadius: '50%',
        margin: 'auto',
        border: `3px solid ${success ? theme.palette.success.main : theme.palette.error.main}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiSvgIcon-root svg path': {
            strokeWidth: 2, stroke: success ? theme.palette.success.main : theme.palette.error.main
        }
    }),
);


const FormPaper = styled(Paper)(() => ({
    height: '400px',
    width: '400px',
    maxHeight: '100vh'
}));


const DialogWithStatus = (props: DialogProps) => {
    const theme = useTheme();
    const { children, onClose, ...other } = props;
    const { showResult, result, loading, loadingTime } = useSelector((state) => state.dialog);

    const { t } = useTranslation();

    const handleClose = (event?: any, reason?: any) => {
        onClose && onClose(event, reason);
        dispatch(setClean());
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (loadingTime !== 0) dispatch(setLoadingTime(0));
        }, loadingTime);
        return () => clearTimeout(timer);
    }, [loadingTime]);

    useEffect(() => {
        return () => {
            dispatch(setClean());
        };
    }, []);

    return (
        <Dialog onClose={handleClose} {...other}
            PaperComponent={showResult ? FormPaper : other?.PaperComponent}>
            {loading && <LinearProgress color="primary" />}

            {showResult ?
                <>
                    <DialogTitle onClose={handleClose}>
                        {t('form.fields.status')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container textAlign='center' p={4}>
                            <Grid item xs={12}>

                                {(loading && loadingTime !== 0) ?
                                    <Box>
                                        <LoaderCircular />
                                    </Box> :

                                    <Box pt={3}>
                                        <Status success={result.success} theme={theme} >
                                            {result.success ? <Checkmark /> : <XClose />}
                                        </Status>
                                    </Box>
                                }

                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <Typography variant="h3">
                                        {(loading && loadingTime !== 0) ? t('transfers.message.processing') : result.success ? t('transfers.message.success') : t('transfers.message.error')}
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <Typography variant="body1">
                                        {(loading && loadingTime !== 0) ? t('transfers.message.pleaseWaitTransaction') : result.message}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </> :
                <>
                    {children}
                </>
            }
        </Dialog >
    );
}

export default DialogWithStatus;
