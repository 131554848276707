export enum RootProcessEnum {
    CREATE_ACCOUNT = 'CREATE_ACCOUNT',
    CREATE_CARD = 'CREATE_CARD',
    CREATE_STOCK = 'CREATE_STOCK',
    CREATE_TRUST = 'CREATE_TRUST',
    CREATE_CRYPTO_ACCOUNT = 'CREATE_CRYPTO_ACCOUNT',
    CREATE_TOKENS = 'CREATE_TOKENS',
    CREATE_VAULT = 'CREATE_VAULT',
    CREATE_WALLET = 'CREATE_WALLET'
}


export enum ProcessTypeEnum {
    ACCOUNT_ACTIVATE = 'ACCOUNT_ACTIVATE',
    ACCOUNT_SUSPEND = 'ACCOUNT_SUSPEND',
    ACCOUNT_TERMINATE = 'ACCOUNT_TERMINATE',
    CARD_ACTIVATE = 'CARD_ACTIVATE',
    CARD_BLOCK = 'CARD_BLOCK',
    CARD_BLOCK_AND_PULL_OUT = 'CARD_BLOCK_AND_PULL_OUT',
    CARD_GET_CVV = 'CARD_GET_CVV',
    CARD_GET_PIN = 'CARD_GET_PIN',
    CARD_PULL_OUT = 'CARD_PULL_OUT',
    CARD_SUSPEND = 'CARD_SUSPEND',
    CARD_TOP_UP = 'CARD_TOP_UP',
    CARD_UPDATE_PASSPHRASE = 'CARD_UPDATE_PASSPHRASE',
    CARD_UPDATE_PHONE = 'CARD_UPDATE_PHONE',
    CREATE_ACCOUNT = 'CREATE_ACCOUNT',
    CREATE_CARD = 'CREATE_CARD',
    CREATE_CARD_COMPLETE = 'CREATE_CARD_COMPLETE',
    CREATE_CRYPTO_ACCOUNT = 'CREATE_CRYPTO_ACCOUNT',
    CREATE_STOCK = 'CREATE_STOCK',
    CREATE_TOKEN_ACCOUNT = 'CREATE_TOKEN_ACCOUNT',
    CREATE_TRUST = 'CREATE_TRUST',
    CREATE_VAULT = 'CREATE_VAULT',
    CREATE_WALLET = 'CREATE_WALLET',
    DEPOSIT = 'DEPOSIT',
    DEPOSIT_FROM_CARD = 'DEPOSIT_FROM_CARD',
    FX_ORDER = 'FX_ORDER',
    MANUAL_ADJUSTMENT = 'MANUAL_ADJUSTMENT',
    MANUAL_DEPOSIT = 'MANUAL_DEPOSIT',
    MANUAL_WITHDRAWAL = 'MANUAL_WITHDRAWAL',
    OWN_TRANSFER = 'OWN_TRANSFER',
    PAYMENT = 'PAYMENT',
    SHIPMENT = 'SHIPMENT',
    SYNC_EXT_TRANSACTION = 'SYNC_EXT_TRANSACTION',
    SYS_BALANCING = 'SYS_BALANCING',
    SYS_CREATE_CRYPTO_ACCOUNT = 'SYS_CREATE_CRYPTO_ACCOUNT',
    SYS_DUMMY = 'SYS_DUMMY',
    SYS_EXECUTE_SYS_TRANSACTION = 'SYS_EXECUTE_SYS_TRANSACTION',
    SYS_FEE = 'SYS_FEE',
    SYS_PLANNED_TRANSFER = 'SYS_PLANNED_TRANSFER',
    SYS_TRANSACTION_ROLLBACK = 'SYS_TRANSACTION_ROLLBACK',
    SYS_UL_SUBSCRIBE = 'SYS_UL_SUBSCRIBE',
    SYS_UTILITY = 'SYS_UTILITY',
    SYS_VENDOR_TRANSFER = 'SYS_VENDOR_TRANSFER',
    TRANSACTION_IMPORT = 'TRANSACTION_IMPORT',
    TRANSACTION_SYNC = 'TRANSACTION_SYNC',
    TRANSFER = 'TRANSFER',
}


export interface ProcessType {
    wlpProcessId: number,
    proc: string,
    userProfileType: string | null,
    userLevelId: string | null,
    userGroupId: string | null,
    type: string,
    root: boolean,
    integration: string,
    providerType: string,
    name: string,
    description: string,
    processProperties: any,
    config: any,
    countryCodes: [],
    subProcesses: ProcessType[]
}

export enum ProcessFeeEnum {
    FIXED = 'FIXED',
    FIXED_MONTH = 'FIXED_MONTH',
    VOLUME = 'VOLUME',
    PAYMENT = 'PAYMENT'
}
