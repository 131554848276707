import * as React from "react";
import { SvgIcon } from '@mui/material';

const Invoices = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                opacity={0.4}
                d="M16.156 19.25a1.552 1.552 0 012.063 0 .62.62 0 001.031-.463V3.213a.62.62 0 00-1.031-.463 1.552 1.552 0 01-2.063 0 1.552 1.552 0 00-2.062 0 1.552 1.552 0 01-2.063 0 1.552 1.552 0 00-2.062 0 1.552 1.552 0 01-2.063 0 1.552 1.552 0 00-2.062 0 1.552 1.552 0 01-2.063 0 .62.62 0 00-1.031.463v15.574a.62.62 0 001.031.463 1.552 1.552 0 012.063 0 1.552 1.552 0 002.062 0 1.552 1.552 0 012.063 0 1.552 1.552 0 002.062 0 1.552 1.552 0 012.063 0 1.552 1.552 0 002.062 0z"
                stroke="currentColor"
                strokeWidth={1.5}
            />
            <path
                d="M6.875 14.208h8.25M6.875 11h8.25M6.875 7.792h8.25"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
        </svg>
    </SvgIcon>
);
export default Invoices;