import { Grid, Typography } from "@mui/material";
import DetailCard from "@/components/cards/DetailCard";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import SummaryNumber from "../components/SummaryNumber";
import { useLocation, useParams } from "react-router";
import ButtonWithIcon from "@/components/buttons/ButtonWithIcon";
import DownloadExport from "@/icons/DownloadExport";
import ChipButtonGroup, { ChipBtn } from "@/components/buttons/ChipButtonGroup";
import { normalizeString } from "@/utils/stringFormat";
import { TransactionType } from "@/redux/emiFinanceBanking/emiFinanceBankingTypes";
import UnassignedPaymentsGrid from "./UnassignedPaymentsGrid";
import { fndDefaultPagination } from "@/redux/fnd/fndSlice";
import { getBakingPaymentsWalletsUuids, paginationParamsInit, setPagination } from "@/redux/emiFinanceBanking/emiFinanceBankingSlice";
import Loader from "@/components/Loader";
import { dispatch } from "@/store/store";

export enum UnassignedPaymentsTabsEnum {
    ALL = "ALL",
    MATCHED = "MATCHED",
    UNMATCHED = "NEW",
    PENDING = "PENDING"
}

const UnassignedPayments = () => {

    const { type, integration } = useParams() as { type: TransactionType, integration: string };
    const [activeType, setActiveType] = useState<UnassignedPaymentsTabsEnum>(UnassignedPaymentsTabsEnum.ALL);

    const [walletUuid, setWalletUuid] = useState<any>('');
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const totalEur = location.state.totalEur;

    const { t } = useTranslation();

    const subtabs = [
        {
            id: 0,
            viewType: UnassignedPaymentsTabsEnum.ALL,
            onClick: () => {
                setActiveType(UnassignedPaymentsTabsEnum.ALL);
                dispatch(setPagination(paginationParamsInit));
            }
        },
        {
            id: 1,
            viewType: UnassignedPaymentsTabsEnum.MATCHED,
            onClick: () => {
                setActiveType(UnassignedPaymentsTabsEnum.MATCHED);
                dispatch(setPagination(paginationParamsInit));
            }
        },
        {
            id: 2,
            viewType: UnassignedPaymentsTabsEnum.UNMATCHED,
            onClick: () => {
                setActiveType(UnassignedPaymentsTabsEnum.UNMATCHED);
                dispatch(setPagination(paginationParamsInit));
            }
        }
    ];


    useEffect(() => {
        const get = async () => {
            setLoading(true)
            try {
                const data = await getBakingPaymentsWalletsUuids('LEDGER_IBAN_EMI_FINANCE', integration, fndDefaultPagination);
                setWalletUuid(data[0]?.meaning);
            } catch (error) {
                setWalletUuid('');
            } finally {
                setLoading(false);
            }
        };
        get();
    }, [integration]);

    const subBtns: ChipBtn[] = subtabs.map(p => {
        return {
            label: t('enum.unassignedPaymentsTabs.' + p.viewType, normalizeString(p.viewType)),
            code: p.viewType,
            onClick: p.onClick
        };
    }) || [];

    if (loading) return <Loader />

    return (
        <Grid container sx={theme => ({ ...theme.typography.mainContentPadding })} >
            <Grid item xs={12} mt='10px'>
                <DetailCard>
                    <Grid container alignItems='center' justifyContent='space-between'>
                        <Grid item xs>
                            <Typography variant='body1' color='text.secondary'>{t('enum.unassignedPayments.' + type)}</Typography>
                            <SummaryNumber
                                variant='h1'
                                amount={totalEur}
                                currency={"EUR"} />
                        </Grid>
                        <Grid item xs='auto'>
                            <ButtonWithIcon
                                icon={<DownloadExport viewBox="0 0 24 24" sx={{ fontSize: '1.2rem', 'path': { strokeWidth: 2 } }} />}
                                buttonText={t('form.buttons.export').toString()}
                                variant="text"
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </DetailCard>
            </Grid>
            < Grid item xs={12} mt={5} >
                {subBtns?.length > 0 && <ChipButtonGroup active={activeType} btnList={subBtns} />}
            </Grid >
            <UnassignedPaymentsGrid key={activeType} activeType={activeType} type={type} integration={integration} walletUuid={walletUuid} />
        </Grid>
    );
}

export default UnassignedPayments;