import { PaginateParams } from "@/types/pagination";
import { AccountStatusEnum, AccountTypeEnum } from "../accounts/accountsTypes";
import { UserType } from "../user/types";

export enum SarStoreType {
    REPORTS = 'REPORTS',
    USER_REPORTS = 'USER_REPORTS'
}

export interface SarStore {
    [key: string]: {
        assignedToMe: SarStoreDetails;
        notAssignedToMe: SarStoreDetails;
    }
};

interface SarStoreDetails {
    loading: boolean,
    list: Array<UserSecurityReport>,
    error: string | null,
    count: number,
    pagination: PaginateParams
}

export enum UserSecurityReportStatus {
    CREATED = 'CREATED',
    IN_REVIEW = 'IN_REVIEW',
    DONE = 'DONE',
    REJECTED = 'REJECTED'
}

export interface UserSecurityReport {
    userId: number;
    fullName: string;
    accountNumber: string;
    suspectDetails: string;
    trxDate: string | null;
    trxAmount: string;
    details: string;
    trxSarsList: string | null;
    status: string;
    acknDate: string | null;
    acknBy: number | null;
    completionDate: string | null;
    completedBy: number | null;
    reportedToAuthority: boolean | null;
    explanation: string | null;
    createdDate: string;
    createdBy: number;
    updatedBy: number;
    suspectAccountId: number;
    clientId: string;
    wlpId: string;
    firstName: string;
    lastName: string;
    companyName: string | null;
    type: string;
    createdByFirstName: string;
    createdByLastName: string;
    entityId: number | null;
    complianceOfficerFirstName: string | null;
    complianceOfficerLastName: string | null;
    id: number;
    complianceOfficerId: number | null;
}

export interface UserProfile {
    firstName: string;
    lastName: string;
    companyName: string | null;
    type: string;
}

export interface CreatedByUser {
    clientId: string;
    profile: UserProfile;
}

export interface Attachment {
    id: string;
    fileName: string;
    fileSize: string;
    fileMimetype: string;
    fileKey: string;
}

export interface User {
    clientId: string;
    source: string;
    profile: UserProfile;
}

export interface UserSecurityReportDetails {
    id: number;
    userId: number;
    fullName: string;
    accountNumber: string;
    suspectDetails: string;
    trxDate: string | null;
    trxAmount: number;
    details: string;
    trxSarsList: string | null;
    status: string;
    acknDate: string | null;
    acknBy: number | null;
    completionDate: string | null;
    completedBy: number | null;
    reportedToAuthority: boolean | null;
    explanation: string | null;
    createdDate: string;
    createdBy: number;
    updatedBy: number;
    suspectAccountId: number;
    user: User;
    createdByUser: CreatedByUser;
    attachments: Attachment[];
}

export interface ReportAttachment {
    id: string;
    fileName: string;
    fileSize: string;
    fileMimetype: string;
    fileKey: string;
}

export interface ReportClient {
    userId: number;
    clientId: string;
    firstName: string;
    lastName: string;
    country: string;
    type: UserType;
    companyName: string;
    companyRegNumber: string;
    companyAddress: string;
    companyCountry: string;
}

export interface ReportAccount {
    id: number;
    balance: string;
    balanceFrozen: string;
    balanceOnHold: string;
    currencyCode: string;
    status: AccountStatusEnum;
    type: AccountTypeEnum;
    walletId: string;
    userId: number;
}