import { FilterOptionType } from '@/components/dataGrid/CustomFilterHeader';
import { PaginateParams } from '@/types/pagination';

export enum StoreKeyEnum {
    MAIN = 'MAIN',
    WALLET = 'WALLET'
}

export interface SystemTransfersStore {
    [key: string]: {
        loading: boolean;
        list: Array<any>;
        error: string | null;
        count: number;
        pagination: PaginateParams;
        filters: {
            [key: string]: Array<FilterOptionType>
        };
        filtersSet: boolean;
        storedWalletId?: number | null;
    },
}


export interface TransferDetails {
    docNumber: string;
    status: string;
    transactions: Array<DocumentTransaction>;
    documentApprovals: Array<DocumentApproval>;
    documentId: number;
    wlpId: string;   
    allowedToApprove: boolean;
    externalReference: string;
    documentDate: string;
    rejectionReason: string;
}

export interface DocumentApproval {
    userId: number;
    user: string;
    firstName: string;
    lastName: string;
    date: number;
    status: string;
};


export interface DocumentTransaction {
    amountString: string;
    amount: number;
    ccy: string;
    displayDecimals: number;
    details: string;
    trxNumber: number;
    type: string;
    transactionDate: string;
    documentType: string;
    walletId: string;
    systemAccountType: string;
    integration: string;
    transactionValueDate: string;
}