import { useTranslation } from "react-i18next";
import { Grid, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import { FeeCategoryCodeType } from "@/redux/platforms/platformsTypes";
import { dispatch, useSelector } from "@/store/store";
import CustomDataGrid, { CustomDataGridRef } from "@/components/dataGrid/CustomDataGrid";
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PaginateParams } from "@/types/pagination";
import CustomFilterHeader, { FilterOptionType, HeaderFilterType } from "@/components/dataGrid/CustomFilterHeader";
import { GridActionsCellItem, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { getPaginationParamsInit, getProcessFilterOptions, getRiskGroupCodeOptions, setPagination } from "@/redux/platformFees/platformFeesSlice";
import { normalizeString } from "@/utils/stringFormat";
import { NumericFormat } from "react-number-format";
import { EmiFee, FeeEnum } from "@/redux/platformFees/platformFeesTypes";
import { FeeScopeEnum, WlpFee } from "@/redux/wlpFees/wlpFeesTypes";
import { getCurrencySign, getIsFiat } from "@/utils/currency";
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { RowActionType } from '@/types/rowActions';
import EditChangePencil from "@/icons/EditChangePencil";
import TypographyWithEllipsis from "@/components/TypographyWithEllipsis";
import { alpha3ToAlpha2 } from "i18n-iso-countries";
import EventIcon from '@mui/icons-material/Event';

import { getAndSetProcessesConfigurations, getWlpFeesByProcesses, setFilter, setFilters, setSelectedCurrency } from "@/redux/wlpFees/wlpFeesSlice";
import AmountValueCell from "@/components/dataGrid/renderCell/AmountValueCell";
import Decimal from "decimal.js";
import Information from "@/icons/Information";
import CurrencySelector from "@/components/dataGrid/CurrencySelector";
import { getRiskGroupCodeColor } from "@/pages/platformDetails/platformFees/feesUtils";
import ForkPlatformFeeForm from "@/pages/platformDetails/platformFees/feeFork/ForkPlatformFeeForm";
import EditPlatformFeeForm from "@/pages/platformDetails/platformFees/EditPlatformFeeForm";
import UserAccount from "@/icons/UserAccount";
import FeesOverrideUserListDialog from "@/pages/platformDetails/platformFees/FeesOverrideUserListDialog";

const MarginCell = ({ fee, ccy, theme }: { fee: WlpFee, ccy: string, theme: Theme }) => {
    const isVolumeFee = fee.feeType === FeeEnum.VOLUME_FEE;
    const isOneTimeAndMonthlyFee = fee.feeType === FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE;

    const minFeeColor = fee.currentMinFee - fee.currentEmiMinFee >= 0 ? theme.palette.primary.dark : theme.palette.error.main;
    const monthlyFeeColor = fee.currentFeeMonth - fee.currentEmiFeeMonth >= 0 ? theme.palette.primary.dark : theme.palette.error.main;
    const feeAmountColor = fee.currentFeeAmount - fee.currentEmiFeeAmount >= 0 ? theme.palette.primary.dark : theme.palette.error.main;

    return (
        <Grid container display='flex' alignItems='center'>
            {isVolumeFee && (
                <Grid container direction='column'>
                    {fee.currentFeePercent !== 0 || fee.currentEmiFeePercent !== 0 ? (
                        <Grid item>
                            <Typography
                                sx={(theme) => ({
                                    color: fee.currentFeePercent - fee.currentEmiFeePercent >= 0
                                        ? theme.palette.primary.dark
                                        : theme.palette.error.main
                                })}>
                                <NumericFormat
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    thousandsGroupStyle='thousand'
                                    thousandSeparator={true}
                                    value={new Decimal(fee.currentFeePercent).minus(fee.currentEmiFeePercent).toNumber()}
                                    suffix={'% '}
                                />
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid container item>
                            <AmountValueCell
                                value={new Decimal(fee.currentMinFee).minus(fee.currentEmiMinFee).toNumber()}
                                currency={ccy}
                                color={minFeeColor}
                                withColor
                            />
                        </Grid>
                    )}
                    {(fee.currentFeePercent !== 0 || fee.currentEmiFeePercent !== 0) && (
                        <Grid container item>
                            <>
                                <Typography color={minFeeColor}>{'(min'}&nbsp;</Typography>
                                <AmountValueCell
                                    value={new Decimal(fee.currentMinFee).minus(fee.currentEmiMinFee).toNumber()}
                                    currency={ccy}
                                    color={minFeeColor}
                                    withColor
                                />
                                <Typography color={minFeeColor}>{')'}</Typography>
                            </>
                        </Grid>
                    )}
                </Grid>
            )}
            {isOneTimeAndMonthlyFee &&
                <Grid container direction='column'>
                    <Grid container item>
                        <AmountValueCell value={new Decimal(fee.currentFeeAmount).minus(fee.currentEmiFeeAmount).toNumber()} currency={ccy} color={feeAmountColor} withColor />
                    </Grid>
                    <Grid container item>
                        <>
                            <Typography color={monthlyFeeColor}>{'(monthly'}&nbsp;</Typography>
                            <AmountValueCell value={fee.currentFeeMonth - fee.currentEmiFeeMonth} currency={ccy} color={monthlyFeeColor} withColor />
                            <Typography color={monthlyFeeColor}>{')'}</Typography>
                        </>
                    </Grid>
                </Grid>
            }
            {!isVolumeFee && !isOneTimeAndMonthlyFee &&
                <AmountValueCell value={new Decimal(fee.currentFeeAmount).minus(fee.currentEmiFeeAmount).toNumber()} currency={ccy} color={feeAmountColor} withColor />
            }
        </Grid>
    );
};


const FeeCell = ({ fee, ccy, t, isEmiFee, theme }: { fee: WlpFee, ccy: string, t: any, isEmiFee: boolean, theme: Theme }) => {
    const isVolumeFee = fee.feeType === FeeEnum.VOLUME_FEE;
    const isOneTimeAndMonthlyFee = fee.feeType === FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE;
    const isNotEditable = fee.currentScope === FeeScopeEnum.VISIBLE_NOT_EDITABLE;
    const color = !isNotEditable ? theme.palette.primary.dark : theme.palette.secondary.dark;

    return (
        <Grid container display='flex' alignItems='center'>
            <Grid>
                {isVolumeFee && (
                    <Grid container direction='column'>
                        {(isEmiFee ? fee.currentEmiFeePercent : fee.currentFeePercent) !== 0 ? (
                            <Grid item>
                                <Typography sx={{ color: color }}>
                                    <NumericFormat
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={false}
                                        thousandsGroupStyle='thousand'
                                        thousandSeparator={true}
                                        value={isEmiFee ? fee.currentEmiFeePercent : fee.currentFeePercent}
                                        suffix={'% '}
                                    />
                                </Typography>
                            </Grid>
                        ) : (
                            <AmountValueCell value={isEmiFee ? fee.currentEmiMinFee : fee.currentMinFee} currency={ccy} color={color} withColor />
                        )}
                        {(isEmiFee ? fee.currentEmiFeePercent : fee.currentFeePercent) !== 0 && (
                            <Grid container item>
                                <Typography color={color}>{'(min'}&nbsp;</Typography>
                                <AmountValueCell value={isEmiFee ? fee.currentEmiMinFee : fee.currentMinFee} currency={ccy} color={color} withColor />
                                <Typography color={color}>{')'}</Typography>
                            </Grid>
                        )}
                    </Grid>
                )}
                {isOneTimeAndMonthlyFee &&
                    <Grid container direction='column'>
                        <Grid item>
                            <AmountValueCell value={isEmiFee ? fee.currentEmiFeeAmount : fee.currentFeeAmount} currency={ccy} color={color} withColor />
                        </Grid>
                        <Grid container item>
                            {(isEmiFee ? fee.currentEmiFeeMonth : fee.currentFeeMonth) !== 0 && (
                                <>
                                    <Typography color={color}>{'(monthly'}&nbsp;</Typography>
                                    <AmountValueCell value={isEmiFee ? fee.currentEmiFeeMonth : fee.currentFeeMonth} currency={ccy} color={color} withColor />
                                    <Typography color={color}>{')'}</Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                }
                {!isVolumeFee && !isOneTimeAndMonthlyFee &&
                    <AmountValueCell value={isEmiFee ? fee.currentEmiFeeAmount : fee.currentFeeAmount} currency={ccy} color={color} withColor />
                }
            </Grid>
            {
                ((isEmiFee && fee.futureEmiFeeId) || (!isEmiFee && fee.futureFeeId)) &&
                <Grid item display='flex' alignItems='center' pl={2}>
                    <Tooltip title={
                        `${t('platforms.fees.futureFeeScheduledOn', {
                            date: new Date(isEmiFee ? fee.futureEmiStartDate : fee.futureStartDate + 'Z').toLocaleString('en-GB', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            })
                        })}`
                    }>
                        <EventIcon sx={{ mr: 1 }} />
                    </Tooltip>
                </Grid>
            }

        </Grid >
    );
};

const getColumn = (
    ref: RefObject<CustomDataGridRef>,
    feeCategoryCode: FeeCategoryCodeType,
    integrationProcessesOptions: Array<FilterOptionType>,
    riskGroupCodeOptions: Array<FilterOptionType>,
    userTypesOptions: Array<FilterOptionType>,
    regionsOptions: Array<FilterOptionType>,
    t: any,
    theme: Theme,
    selectedCurrency: string,
    actionItems: Array<RowActionType<WlpFee>>,
    handleChangeFilter: (filterType: HeaderFilterType, filterOptions: Array<FilterOptionType>) => void,
    isEmi?: boolean,
) => {
    const withSubValues = integrationProcessesOptions.some(option => option.subValues && option.subValues.length > 0);
    const ccy = selectedCurrency ? getCurrencySign(selectedCurrency) : selectedCurrency;
    let columns: GridColDef[] = [
        {
            field: 'processName',
            headerName: t('form.fields.process'),
            flex: 1.5,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                const withDetials = params.value.includes('GlobalMail');
                return (
                    <Grid container alignItems='center'>
                        <Grid container item xs={12} columnGap={1}>
                            <Grid item maxWidth={withDetials ? '80%' : '100%'}>
                                <TypographyWithEllipsis variant="body1">{params.value}</TypographyWithEllipsis>
                            </Grid>
                            {withDetials &&
                                <Tooltip title={params.row.processDescription} placement="top"
                                    PopperProps={{
                                        modifiers: [
                                            {
                                                name: 'offset',
                                                options: {
                                                    offset: [0, -10],
                                                },
                                            },
                                        ],
                                    }}>
                                    <Grid item display='flex' alignItems='center'>
                                        <Information sx={{ fontSize: '18px' }} />
                                    </Grid>
                                </Tooltip>}
                        </Grid>
                        <Grid container item>
                            {(feeCategoryCode === FeeCategoryCodeType.BANKING || feeCategoryCode === FeeCategoryCodeType.CRYPTO || feeCategoryCode === FeeCategoryCodeType.CARD)
                                && <Typography variant="body2" color="primary">{params.row.integrationName}</Typography>}
                        </Grid>
                    </Grid>
                );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
                <CustomFilterHeader
                    fieldName={withSubValues ? "integration" : "process"}
                    filterType={HeaderFilterType.PROCESSES}
                    headerName={params.colDef.headerName ?? ""}
                    options={integrationProcessesOptions}
                    subFieldName={withSubValues ? "process" : undefined}
                    withSubValues={withSubValues}
                    updatePaginationParams={ref?.current?.updatePaginationParams}
                    setFilterOptions={handleChangeFilter}
                />
            ),
        },
        {
            field: 'userProfileType',
            headerName: t('form.fields.userType'),
            flex: 0.9,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (params) => {
                return t('enum.userType' + params.value, normalizeString(params.value));
            },
            renderHeader: (params: GridColumnHeaderParams) => (
                <CustomFilterHeader
                    fieldName="userProfileType"
                    filterType={HeaderFilterType.USER_TYPES}
                    headerName={params.colDef.headerName ?? ""}
                    options={userTypesOptions}
                    updatePaginationParams={ref?.current?.updatePaginationParams}
                    setFilterOptions={handleChangeFilter}
                />
            ),
        },
        {
            field: 'riskGroupCode',
            headerName: t('form.fields.riskGroup'),
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                if (params.value === "0-DEFAULT") return "All";
                return (
                    <Typography sx={{ color: getRiskGroupCodeColor(params.value, theme) }}>
                        {t("compliance.riskGroup." + params.value, normalizeString(params.value))}
                    </Typography>
                );
            },
            renderHeader: (params: GridColumnHeaderParams) => (
                <CustomFilterHeader
                    fieldName="riskGroupCode"
                    filterType={HeaderFilterType.RISK_GROUPS}
                    headerName={params.colDef.headerName ?? ""}
                    options={riskGroupCodeOptions}
                    updatePaginationParams={ref?.current?.updatePaginationParams}
                    setFilterOptions={handleChangeFilter}
                />
            ),
        },
        {
            field: 'countryCode',
            headerName: t('form.fields.regionCountry'),
            flex: 1.3,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                if (params.value === "0-DEFAULT" && params.row.regionCode === "0-DEFAULT") return "All Regions";
                else if (params.value && params.value !== "0-DEFAULT") {
                    const iso2 = alpha3ToAlpha2(params.row.countryCode)?.toLowerCase() || '';
                    return (
                        <Grid container>
                            <Grid item container xs={12} alignItems='center' columnGap={0.5}>
                                <img
                                    loading="lazy"
                                    width="21"
                                    height="14"
                                    src={`https://flagcdn.com/w20/${iso2}.png`}
                                    srcSet={`https://flagcdn.com/w40/${iso2}.png 2x`}
                                    alt="Country flag"
                                />
                                <TypographyWithEllipsis variant="body1">{params.row.countryName}</TypographyWithEllipsis>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="primary">{params.row.regionName}</Typography>
                            </Grid>
                        </Grid>
                    );
                }
                else return <TypographyWithEllipsis variant="body1">{params.row.regionName}</TypographyWithEllipsis>;
            },
            renderHeader: (params: GridColumnHeaderParams) => (
                <CustomFilterHeader
                    fieldName="regionCode"
                    filterType={HeaderFilterType.REGION_CODE}
                    headerName={params.colDef.headerName ?? ""}
                    options={regionsOptions}
                    allSubsWithNull={true}
                    subFieldName="countryCode"
                    withSubValues={true}
                    updatePaginationParams={ref?.current?.updatePaginationParams}
                    setFilterOptions={handleChangeFilter}
                />
            ),
        },
        ...(feeCategoryCode !== FeeCategoryCodeType.COMPLIANCE ? [
            {
                field: 'clientFee',
                headerName: t('form.fields.clientFee'),
                flex: 1.6,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    return <FeeCell fee={params.row as WlpFee} ccy={ccy} t={t} isEmiFee={false} theme={theme} />
                },
            }
        ] : []),
        {
            field: 'yourCost',
            headerName: isEmi ? t('form.fields.platformCost') : t('form.fields.yourCost'),
            flex: feeCategoryCode === FeeCategoryCodeType.COMPLIANCE ? 1 : 1.6,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return <FeeCell fee={params.row as WlpFee} ccy={ccy} t={t} isEmiFee={true} theme={theme} />
            },
        },
        ...(feeCategoryCode !== FeeCategoryCodeType.COMPLIANCE ? [
            {
                field: 'margin',
                headerName: t('form.fields.margin'),
                flex: 1.2,
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    return <MarginCell fee={params.row as WlpFee} ccy={ccy} theme={theme} />
                },
            }
        ] : [])
    ];

    if (feeCategoryCode !== FeeCategoryCodeType.COMPLIANCE && !isEmi) {
        columns.push({
            field: 'actions',
            type: 'actions',
            headerName: t('form.fields.actions'),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            sortable: false,
            getActions: (params: GridRowParams) => actionItems.map(item => {
                const icon: JSX.Element =
                    ((item.key === "edit" && params.row.currentScope === FeeScopeEnum.VISIBLE_NOT_EDITABLE) ||
                        (item.key === "fork" && (params.row.currentScope === FeeScopeEnum.VISIBLE_NOT_EDITABLE || (params.row.countryCode !== "0-DEFAULT"))))
                        && item.iconOpposite
                        ? item.iconOpposite
                        : item.icon;

                const isDisabledEdit: boolean = item.key === "edit" && params.row.currentScope === FeeScopeEnum.VISIBLE_NOT_EDITABLE;
                const isDisabledFork: boolean = item.key === "fork" && (params.row.currentScope === FeeScopeEnum.VISIBLE_NOT_EDITABLE ||
                    (params.row.countryCode !== "0-DEFAULT"));

                const isUserOverridesVisible: boolean = params.row.countryCode === "0-DEFAULT" && params.row.regionCode === "0-DEFAULT" && params.row.riskGroupCode === "0-DEFAULT";

                if (!isUserOverridesVisible && item.key === 'overrides' && params.row.feeCategoryCode !== FeeCategoryCodeType.COMPLIANCE) {
                    return <></>;
                }

                return (
                    <GridActionsCellItem

                        icon={icon}
                        onClick={() => { item.onClick(params.row) }}
                        label=""
                        disabled={(item.key === "edit" && isDisabledEdit) || (item.key === "fork" && isDisabledFork)}
                    />
                )
            })
        });
    }
    return columns;
};

interface Props {
    wlpId?: string;
    feeType: FeeCategoryCodeType;
};


const WlpFeesTab = ({ feeType, wlpId }: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { isEmi } = useSelector((state) => state.credentials);

    const { currenciesDecimals } = useSelector((state) => state.currencies);

    const { [feeType]: { list, count, loading, pagination, error, loadingProcesses, selectedCurrency, uniqueCcy, filters, filtersSet, processesConfigurations } } = useSelector((state) => state.wlpFees.fees);
    const { loadingRegions, regionsOptions } = useSelector((state) => state.wlpFees.commonFilterOptions);

    useEffect(() => {
        if (processesConfigurations === null && selectedCurrency)
            dispatch(getAndSetProcessesConfigurations(feeType, selectedCurrency, wlpId));
    }, [feeType, processesConfigurations, selectedCurrency, wlpId])

    const [loadingConfig, setLoadingConfig] = useState(true);
    useEffect(() => {
        setLoadingConfig(loadingProcesses || loadingRegions);
    }, [loadingProcesses, loadingRegions])

    const displayDecimals = currenciesDecimals.find(ccy => ccy.ccy === selectedCurrency)?.decimals ?? (getIsFiat(selectedCurrency) ? 2 : 5)

    const ref = useRef<CustomDataGridRef>(null);

    const ccyBtns = uniqueCcy;

    const paginationParamsInit = getPaginationParamsInit(feeType);

    const [selectedRow, setSelectedRow] = useState<EmiFee | WlpFee | null>(null);

    const onFeeCellClick = (fee: EmiFee | WlpFee) => {
        setSelectedRow(fee);
    };

    const [open, setOpen] = useState(false);
    const [openFork, setOpenFork] = useState(false);
    const [openOverridesList, setOpenOverridesList] = useState(false);

    const fetch = async (searchParams: PaginateParams, ccy?: string) => {
        await dispatch(getWlpFeesByProcesses(feeType, ccy ? ccy : selectedCurrency, searchParams, wlpId));
    };

    useEffect(() => {
        if (!filtersSet && regionsOptions.length > 0) {
            dispatch(setFilters({ type: feeType }))
        }
    }, [feeType, filtersSet, regionsOptions]);

    const handleChangeFilter = useCallback((filterType: HeaderFilterType, filterOptions: Array<FilterOptionType>) => {
        dispatch(setFilter({ filterType: filterType, type: feeType, options: filterOptions }));
    }, [feeType]);

    const columns = useMemo(() => {
        const actionItems = [
            {
                key: 'fork',
                icon: <AltRouteIcon />,
                iconOpposite: <AltRouteIcon sx={{ color: "#82918E66" }} />,
                onClick: (fee: WlpFee) => { onFeeCellClick(fee); setOpenFork(true); }
            },
            {
                key: 'edit',
                icon: <EditChangePencil />,
                iconOpposite: <EditChangePencil color="#82918E66" />,
                onClick: (fee: WlpFee) => { onFeeCellClick(fee); setOpen(true); }
            },
            {
                key: 'overrides',
                icon: <UserAccount />,
                iconOpposite: <UserAccount color="#82918E66" />,
                onClick: (fee: WlpFee) => { onFeeCellClick(fee); setOpenOverridesList(true); },
            },
        ];
        return getColumn(ref, feeType, filters[HeaderFilterType.PROCESSES], filters[HeaderFilterType.RISK_GROUPS], filters[HeaderFilterType.USER_TYPES], filters[HeaderFilterType.REGION_CODE], t, theme, selectedCurrency, actionItems, handleChangeFilter, isEmi)
    }, [ref, isEmi, feeType, filters, t, selectedCurrency, theme, handleChangeFilter]);

    const handleCurrencyChange = (currency: string) => {
        if (processesConfigurations) {
            const filteredProcessesConfigurations = getProcessFilterOptions(processesConfigurations, currency);
            dispatch(setFilter({ filterType: HeaderFilterType.PROCESSES, type: feeType, options: filteredProcessesConfigurations }));
        };
        dispatch(setSelectedCurrency({ type: feeType, currency }));
        dispatch(setPagination({ type: feeType, pagination: paginationParamsInit }));
        fetch(paginationParamsInit, currency);
    };

    const customButtonContent = ccyBtns && ccyBtns.length > 1 && (
        <CurrencySelector
            ccyBtns={ccyBtns}
            selectedCurrency={selectedCurrency}
            handleCurrencyChange={handleCurrencyChange} />
    );

    const onFeeChange = () => {
        fetch(pagination, selectedCurrency);
    };

    return (
        <>
            {selectedRow &&
                <EditPlatformFeeForm
                    platformFee={selectedRow}
                    open={open}
                    decimalScale={displayDecimals}
                    onClose={() => setOpen(false)}
                    onComplete={() => {
                        onFeeChange();
                        setOpen(false);
                    }}
                />
            }
            {selectedRow &&
                <FeesOverrideUserListDialog
                    platformFee={selectedRow as WlpFee}
                    open={openOverridesList}
                    onClose={() => setOpenOverridesList(false)}
                />
            }
            {selectedRow && regionsOptions &&
                <ForkPlatformFeeForm
                    key={selectedRow.uuid}
                    fee={selectedRow}
                    decimalScale={displayDecimals}
                    regionsOptions={regionsOptions}
                    riskGroupOptions={getRiskGroupCodeOptions()}
                    selectedCurrency={selectedCurrency}
                    open={openFork}
                    onClose={() => setOpenFork(false)}
                    onComplete={() => {
                        onFeeChange();
                        setOpenFork(false)
                    }}
                />
            }
            <CustomDataGrid
                ref={ref}
                rows={list}
                getRowId={(row) => `${row.uuid}`}
                rowCount={count}
                fetch={fetch}
                loading={loading}
                paginationParams={pagination}
                paginationParamsInit={paginationParamsInit}
                columns={columns}
                loadingError={error}
                withSearch
                rowHeight={70}
                withRefreshBtn
                customButton={customButtonContent}
                readyToFetchCondition={!loadingConfig}
            />
        </>
    );
};

export default WlpFeesTab;