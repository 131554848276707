import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { Account, AccountDetailsResponseType, AccountStatusEnum, SystemAccountTypeEnum, SystemAccountsStats, SystemAccountsStore } from './systemAccountsTypes';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { t } from '@/utils/translate';
import { Wlp } from '../user/types';
import { getWlpName } from '@/components/dataGrid/utils/Platform';

export const paginationParamsInit = {
	filter: '',
	orderBy: 'balanceEur',
	sort: SortDirectionEnum.DESC,
	take: 15,
	skip: 0
};

const initialState: SystemAccountsStore = {
	accountTabs: {
		[SystemAccountTypeEnum.BANK_AND_WALLET]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[SystemAccountTypeEnum.CRYPTO]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[SystemAccountTypeEnum.TRUST]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[SystemAccountTypeEnum.TOKEN]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[SystemAccountTypeEnum.CARD]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.WLPS]: [],
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		}
	},
	accountStatistics: null,
	accountStatisticsLoading: false,
};


const slice = createSlice({
	name: 'systemAccounts',
	initialState,
	reducers: {
		setAccounts(state, action: PayloadAction<{ type: SystemAccountTypeEnum, list: Array<Account> }>) {
			state.accountTabs[action.payload.type].list = action.payload.list;
		},
		setLoading: (state, action: PayloadAction<{ type: SystemAccountTypeEnum, loading: boolean }>) => {
			state.accountTabs[action.payload.type].loading = action.payload.loading
		},
		setError: (state, action: PayloadAction<{ type: SystemAccountTypeEnum, error: string }>) => {
			state.accountTabs[action.payload.type].error = action.payload.error;
		},
		setCount: (state, action: PayloadAction<{ type: SystemAccountTypeEnum, count: number }>) => {
			state.accountTabs[action.payload.type].count = action.payload.count;
		},
		setPagination: (state, action: PayloadAction<{ type: SystemAccountTypeEnum, pagination: PaginateParams }>) => {
			state.accountTabs[action.payload.type].pagination = action.payload.pagination;
		},
		// Filters
		setFilters: (state, action: PayloadAction<{ type: SystemAccountTypeEnum, wlps: Array<Wlp>, currencies: Array<FilterOptionType> }>) => {
			const wlpsOptions = action.payload.wlps.map(item => ({
				value: item.wlpId,
				text: getWlpName(item.wlpId),
				enabled: true
			}));
			const statusOptions = getAccountStatusOptions();
			state.accountTabs[action.payload.type].filters[HeaderFilterType.WLPS] = wlpsOptions;
			state.accountTabs[action.payload.type].filters[HeaderFilterType.STATUS] = statusOptions;
			state.accountTabs[action.payload.type].filters[HeaderFilterType.CURRENCY] = action.payload.currencies;
			state.accountTabs[action.payload.type].filtersSet = true;
		},
		setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, type: SystemAccountTypeEnum, options: Array<FilterOptionType> }>) => {
			state.accountTabs[action.payload.type].filters[action.payload.filterType] = action.payload.options;
		},
		setFiltersSet(state, action: PayloadAction<{ type: SystemAccountTypeEnum, set: boolean }>) {
			state.accountTabs[action.payload.type].filtersSet = action.payload.set;
		}
	}
});

export const { setLoading, setError, setCount, setPagination, setAccounts, setFilters, setFilter, setFiltersSet } = slice.actions;

export const getSystemAccounts = (type: SystemAccountTypeEnum, payload: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading({ type, loading: true }));
			const response = await request.get('/api/console/accounts/list', {
				params: {
					isSystem: true,
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
					...({ type: type === SystemAccountTypeEnum.BANK_AND_WALLET ? 'BANK_AND_WALLET' : type })
				}
			});
			const { data } = response;
			dispatch(setAccounts({ type, list: data.list }));
			dispatch(setCount({ type, count: data.count }));
			dispatch(setPagination({ type, pagination: payload }));
			dispatch(setLoading({ type, loading: false }));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading({ type, loading: false }));
		}
	};
};

export const getSystemAccount = async (accountId: number): Promise<AccountDetailsResponseType> => {
	const response = await request.get(`/api/console/system/accounts/${accountId}`);
	const { data } = response;
	return data;
};

export const getSystemAccountsStatistics = async (): Promise<SystemAccountsStats> => {
	const response = await request.get('/api/console/system/accounts/emi/statistics');
	const { data } = response;
	return data;
};


export const getAccountStatusOptions = (): Array<FilterOptionType> => {
	return Object.keys(AccountStatusEnum)
		.filter(key => !key.startsWith('CARD'))
		.map(key => {
			return {
				value: key,
				text: t('enum.accountStatusEnum.' + key),
				enabled: true
			};
		});
};




export default slice.reducer;
