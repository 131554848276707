import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { FeeCategoryCodeType, PlatformsTab } from "@/redux/platforms/platformsTypes";
import TabsButton from "@/components/TabsButton";
import { dispatch, useSelector } from "@/store/store";
import TabButton from "@/components/TabButton";
import { getRegions, reset, setStoredWlpId } from "@/redux/wlpFees/wlpFeesSlice";
import WlpServiceFeesTab from "./WlpServiceFeesTab";
import WlpFeesTab from "./WlpFeesTab";
import { setSubTab } from "@/redux/platforms/platformsSlice";

interface Props {
    wlpId?: string;
};

const WlpFees = ({ wlpId }: Props) => {
    const { t } = useTranslation();

    const { storedWlpId } = useSelector((state) => state.wlpFees.commonFilterOptions);

    const [openedSubTabForWlp, setOpenedSubTab] = useState(FeeCategoryCodeType.COMPLIANCE);

    const storeKey = PlatformsTab.WLP_FEES;
    const { [storeKey]: { openedSubTab: openedSubTabForEmi } } = useSelector((state) => state.platforms.tabs);

    const openedSubTab = wlpId ? openedSubTabForEmi : openedSubTabForWlp;

    useEffect(() => {
        if (wlpId && wlpId !== storedWlpId) {
            dispatch(reset());
            dispatch(setStoredWlpId(wlpId));
            dispatch(setSubTab({ tab: storeKey, subTab: FeeCategoryCodeType.COMPLIANCE }));
        };
    }, [wlpId, storedWlpId, storeKey]);

    const fetchRegions = useCallback(() => {
        dispatch(getRegions(wlpId));
    }, [wlpId])

    useEffect(() => {
        fetchRegions()
    }, [fetchRegions])

    const tabsOption = [
        {
            value: 0,
            label: t('pages.tabs.compliance'),
            feeType: FeeCategoryCodeType.COMPLIANCE,
        },
        {
            value: 1,
            label: t('pages.tabs.software'),
            feeType: FeeCategoryCodeType.SOFTWARE,
        },
        {
            value: 2,
            label: t('pages.tabs.banking'),
            feeType: FeeCategoryCodeType.BANKING,
        },
        {
            value: 3,
            label: t('pages.tabs.cards'),
            feeType: FeeCategoryCodeType.CARD,
        },
        {
            value: 5,
            label: t('pages.tabs.crypto'),
            feeType: FeeCategoryCodeType.CRYPTO,
        }
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <TabsButton
                    value={openedSubTab}
                    onChange={(e, val) => {
                        wlpId ? dispatch(setSubTab({ tab: storeKey, subTab: val })) : setOpenedSubTab(val);
                    }}
                    variant="scrollable"
                    scrollButtons="auto">
                    {tabsOption.map((item) => (
                        <TabButton label={item.label} key={item.value} value={item.feeType} />
                    ))}
                </TabsButton>
            </Grid>
            <Grid item xs={12} mt={2}>
                {openedSubTab === FeeCategoryCodeType.SOFTWARE ?
                    <WlpServiceFeesTab
                        key={openedSubTab}
                        feeType={openedSubTab}
                        wlpId={wlpId}
                    /> :
                    <WlpFeesTab
                        key={openedSubTab}
                        feeType={openedSubTab}
                        wlpId={wlpId}
                    />}
            </Grid>
        </Grid>
    )
};

export default WlpFees;