export enum Folders {
	KYC = "kyc",
	AVATAR = "avatar",
	TOKENS_LOGO = "tokens_logo",
	VAULTS_LOGO = "vaults_logo",
	USER_NOTES = "user_notes",
	SAR_DOCUMENTS = "sar_documents",
	CHATS = "chats",
	PAYMENTS = "payments",
	PRODUCT_IMAGES = "product_images"
};

export enum FileScanStatus {
	SCANNING = "SCANNING",
	SAFE = "SAFE",
	BLOCKED = "BLOCKED",
	WARNING = "WARNING",
	UNKNOWN = "UNKNOWN",
};