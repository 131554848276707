import DetailCard from "@/components/cards/DetailCard";
import { CardDetails, CardShipmentCountry } from "@/redux/cards/cardsTypes";
import { Button, CardMedia, DialogContent, Grid, Typography } from "@mui/material";
import { isValidElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import fallback from '../../../assets/images/fallbackCard.png';
import { getDeliveryCountries } from "@/redux/cards/cardsSlice";
import CardReplaceConfirm from "./CardReplaceConfirm";
import DialogTitle from "@/components/DialogTitle";
import { getClientAccounts } from "@/redux/accounts/accountsSlice";
import { showErrorNotification } from "@/utils/errors";
import { Account } from "@/redux/accounts/accountsTypes";
import FormBack from "@/components/FormBack";
import CardReplaceDelivery from "./CardReplaceDelivery";
import { DetailInfoProps } from "@/components/DetailInfo";
import { t } from "@/utils/translate";
import { getUserFeesByType } from "@/redux/userFees/userFeesSlice";
import { RootProcessEnum } from "@/redux/process/processTypes";
import { FeeCategory, FeeDetail } from "@/redux/userFees/userFeesTypes";
import DialogWithStatus from "@/components/DialogWithStatus";
import FormPaperLarge from "@/components/FormPaperLarge";
import { setLoadingDialog } from "@/redux/dialog/dialogSlice";
import { dispatch, useSelector } from "@/store/store";
import { NumericFormat } from "react-number-format";
import AmountValueCell from "@/components/dataGrid/renderCell/AmountValueCell";

export interface CardDeliveryAddressType {
    address: string,
    postalCode: string,
    city: string,
    countryName: string,
    countryIso2: string,
    countryIso3: string,
}
interface Props {
    open: boolean,
    onClose: () => void,
    card: CardDetails,
}
const  enum StepEnum {
    INFO = 'INFO',
    DELIVERY = 'DELIVERY',
    CONFIRM = 'CONFIRM',
}

const EmiCardReplaceDialog = ({ open, card, onClose }: Props) => {
    const { t } = useTranslation();

    const { loading } = useSelector((state) => state.dialog);

    const [accountList, setAccountList] = useState<Account[]>([]);
    const [cardFees, setCardFees] = useState<FeeCategory | null>(null);

    const [loadingCardFees, setLoadingCardFees] = useState(true);
    const [loadingAccounts, setLoadingAccounts] = useState(true);
    const [loadingCountries, setLoadingCountries] = useState(true);

    const [step, setStep] = useState<StepEnum>(StepEnum.INFO);
    const [deliveryAddress, setDeliveryAddress] = useState<CardDeliveryAddressType>();
    const [deliveryCountries, setDeliveryCountries] = useState<CardShipmentCountry[]>([]);

    const replaceSubProcess = Object.values(card.subProcesses || {}).find(p => p.type === "REPLACE_CARD");

    useEffect(() => {
        if (loadingCardFees || loadingAccounts || loadingCountries)
            dispatch(setLoadingDialog(true));
        else dispatch(setLoadingDialog(false));
    }, [loadingCardFees, loadingAccounts, loadingCountries])

    const fetchAccounts = useCallback(() => {
        if (!card.wlpId || !card.userId || !card.currency) return;

        const get = async () => {
            setLoadingAccounts(true);
            try {
                const data = await getClientAccounts(card.wlpId, card.userId, card.currency);
                setAccountList(data.list || []);
            } catch (e) {
                setAccountList([]);
                showErrorNotification(e);
            } finally {
                setLoadingAccounts(false);
            }
        };
        get();
    }, [card.wlpId, card.userId, card.currency]);

    useEffect(() => {
        fetchAccounts();
    }, [fetchAccounts]);

    useEffect(() => {
        const getCountries = async () => {
            try {
                setLoadingCountries(true);
                const data = await getDeliveryCountries() || [];
                const allowedDeliveryCountries = data.filter(p => p.integration === card.integration) || [];
                setDeliveryCountries(allowedDeliveryCountries);
            }
            catch (e) {
                setDeliveryCountries([]);
            } finally {
                setLoadingCountries(false);
            }
        };
        getCountries();
    }, [card.integration])

    useEffect(() => {
        const getCardFees = async () => {
            try {
                setLoadingCardFees(true);
                const data = await getUserFeesByType(card.userId, RootProcessEnum.CREATE_CARD) || [];
                const procFees = data[0].fees[card.proc];
                setCardFees(procFees);
            } catch (e) {
                setCardFees(null);
            } finally {
                setLoadingCardFees(false);
            }
        };
        getCardFees();
    }, [card.userId, card.proc])

    const renderContent = () => {
        if (step === StepEnum.INFO) {
            return <CardFeeDetails
                card={card}
                cardFees={cardFees}
                onNext={() => {
                    if (!card.virtual) {
                        setStep(StepEnum.DELIVERY)
                    } else {
                        setStep(StepEnum.CONFIRM)
                    }
                }}
                onClose={onClose}
            />
        };
        if (step === StepEnum.DELIVERY) {
            return <CardReplaceDelivery
                oldCard={card}
                deliveryAddress={deliveryAddress}
                countryList={deliveryCountries}
                onConfirm={(item) => {
                    setDeliveryAddress(item);
                    setStep(StepEnum.CONFIRM)
                }}
                onClose={onClose}
            />;
        };

        if (step === StepEnum.CONFIRM && cardFees && replaceSubProcess) {
            return <CardReplaceConfirm
                card={card}
                cardFees={cardFees}
                isPhysical={!card.virtual}
                validateAddress={!!replaceSubProcess?.config?.validateAddress}
                currency={replaceSubProcess?.fees[0]?.ccy}
                deliveryAddress={deliveryAddress}
                accountList={accountList}
                onClose={onClose}
            />;
        };
    }

    const goBack = () => {
        if (step === StepEnum.INFO) { setStep(StepEnum.INFO) }
        if (step === StepEnum.DELIVERY) { setStep(StepEnum.INFO) }
        if (step === StepEnum.CONFIRM) { setStep(!card.virtual ? StepEnum.DELIVERY : StepEnum.INFO) }
    }

    return (
        <DialogWithStatus
            open={open}
            onClose={onClose}
            fullWidth
            PaperComponent={FormPaperLarge}
        >
            <DialogTitle onClose={onClose}>
                <FormBack onClick={goBack} title={t('cards.actions.title.REPLACE')} hideBackOption={step === StepEnum.INFO} />
            </DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <Grid container mt={1}>
                    {!loading && renderContent()}
                </Grid>
            </DialogContent>
        </DialogWithStatus >
    );
}

const getCardFeeDetails = (cardFees: Record<string, Array<FeeDetail>>, feeTypes: Array<string>): Array<DetailInfoProps> => {
    const details = feeTypes.flatMap((type) => {
        const fee = cardFees[type]?.[0];
        const isVolume = fee && fee.type === 'VOLUME';
        return fee
            ? [{
                field: t(`cards.feeEnum.${type}`).toString(),
                value: isVolume ?
                    <>
                        <NumericFormat
                            displayType={'text'}
                            decimalScale={2}
                            fixedDecimalScale={false}
                            thousandsGroupStyle='thousand'
                            thousandSeparator={true}
                            value={fee.feePercent}
                            suffix={'% '}
                        />
                        <Typography>&nbsp;{'(min'}&nbsp;</Typography>
                        <AmountValueCell value={fee.minFee} currency={fee.ccy} />
                        <Typography>{')'}</Typography>
                    </> :
                    <AmountValueCell value={fee.feeAmount} currency={fee.ccy} />
            }]
            : [];
    });

    return details;
};


const CardFeeDetails = ({ card, cardFees, onNext, onClose }: { card: CardDetails, cardFees: FeeCategory | null, onNext: () => void, onClose: () => void }) => {
    const feesTypes = ['REPLACE_CARD', 'FIXED_MONTHLY_SERVICE_FEE', 'CARD_TOP_UP', 'PURCHASE', 'PURCHASE_ONLINE', 'ATM_WITHDRAWAL_EU', 'ATM_WITHDRAWAL_WORLD']
    const details = cardFees ? getCardFeeDetails(cardFees, feesTypes) : [];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container item justifyContent='center' pt={2}>
                    <CardMedia
                        image={card.logo ?? fallback}
                        onError={(e) => (e.currentTarget.src = fallback)}
                        component="img"
                        sx={{ borderRadius: '5px', maxWidth: '300px' }} />
                </Grid>
                <Grid item px={1} pt={3}>
                    <CardDetailsInfo details={details} />
                </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent='space-between' mt='auto' pt={2} pb={2}>
                <Grid item xs="auto">
                    <Button color='secondary' onClick={onClose} variant='outlined'>{t('form.buttons.cancel')}</Button>
                </Grid>
                <Grid item xs="auto" onClick={onNext}>
                    <Button >{t('form.buttons.continue')}</Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const CardDetailsInfo = ({ details }: { details: Array<DetailInfoProps> }) => {
    return (
        <DetailCard
            light={false}
            sx={{ height: '100%' }}
        >
            <Grid container columnSpacing={2} rowSpacing={1.5} justifyContent='space-between'>
                {details.map((p, idx) => {
                    return (
                        <Grid key={idx} container item xs={12}>
                            <Grid container alignItems='center'>
                                <Grid item xs={6} alignItems='center'>
                                    {isValidElement(p.field) ? p.field :
                                        <Typography variant="body2" color='text.secondary'>
                                            {p.field}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xs={6} container alignItems='center'>
                                    {isValidElement(p?.value) ? p.value ?? '-' :
                                        <Typography variant='body2' color={p?.value === 'N/A' ? 'textSecondary' : 'primary'}>
                                            {p?.value ?? '-'}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </DetailCard>
    )
};


export default EmiCardReplaceDialog;

