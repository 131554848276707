import { showErrorNotificationWithContent, showInfoNotificationWithContent, showWarningNotificationWithContent } from '@/utils/errors';
import request from '../../services/request';
import { FileScanStatus, Folders } from './filesTypes';
import { t } from '@/utils/translate';


export const isFileStatusValid = (status: FileScanStatus): boolean => {
	if (status !== FileScanStatus.SAFE) {
		if (status === FileScanStatus.BLOCKED) {
			showErrorNotificationWithContent(t(`errors.fileScanStatus.${status}`));
		} else if (status === FileScanStatus.SCANNING) {
			showInfoNotificationWithContent(t(`errors.fileScanStatus.${status}`));
		} else {
			showWarningNotificationWithContent(t(`errors.fileScanStatus.${status}`));
		}
		return false;
	} else {
		return true;
	}
};

export const checkFileScanStatus = async (key: string, folder: Folders): Promise<{ status: FileScanStatus; message: string }> => {
	const response = await request.get(`/api/console/files/status/${key}`, {
		params: { folder }
	});
	const { data } = response;
	return data;
};