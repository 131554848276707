import * as React from "react";
import { SvgIcon } from '@mui/material';

const SystemAccounts = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M14.5118 8.67851C15 8.19036 15 7.40468 15 5.83333C15 4.26198 15 3.47631 14.5118 2.98816M14.5118 8.67851C14.0237 9.16667 13.238 9.16667 11.6667 9.16667H8.33333C6.76199 9.16667 5.97631 9.16667 5.48816 8.67851M14.5118 8.67851C14.5118 8.67851 14.5118 8.67851 14.5118 8.67851ZM14.5118 2.98816C14.0237 2.5 13.238 2.5 11.6667 2.5L8.33333 2.5C6.76198 2.5 5.97631 2.5 5.48816 2.98816M14.5118 2.98816C14.5118 2.98816 14.5118 2.98816 14.5118 2.98816ZM5.48816 2.98816C5 3.47631 5 4.26199 5 5.83333C5 7.40468 5 8.19036 5.48816 8.67851M5.48816 2.98816C5.48816 2.98816 5.48816 2.98816 5.48816 2.98816ZM5.48816 8.67851C5.48816 8.67851 5.48816 8.67851 5.48816 8.67851Z" stroke="currentColor" />
            <path opacity="0.5" d="M10.8327 5.83333C10.8327 6.29357 10.4596 6.66667 9.99935 6.66667C9.53911 6.66667 9.16602 6.29357 9.16602 5.83333C9.16602 5.3731 9.53911 5 9.99935 5C10.4596 5 10.8327 5.3731 10.8327 5.83333Z" stroke="currentColor" strokeWidth="0.7" />
            <path opacity="0.5" d="M15 5C13.6193 5 12.5 3.88071 12.5 2.5" stroke="currentColor" strokeWidth="0.8" strokeLinecap="round" />
            <path opacity="0.5" d="M15 6.6665C13.6193 6.6665 12.5 7.78579 12.5 9.1665" stroke="currentColor" strokeWidth="0.8" strokeLinecap="round" />
            <path opacity="0.5" d="M5 5C6.38071 5 7.5 3.88071 7.5 2.5" stroke="currentColor" strokeWidth="0.8" strokeLinecap="round" />
            <path opacity="0.5" d="M5 6.6665C6.38071 6.6665 7.5 7.78579 7.5 9.1665" stroke="currentColor" strokeWidth="0.8" strokeLinecap="round" />
            <path opacity="0.5" d="M3.33398 17.8237H5.21726C6.05965 17.8237 6.91109 17.9114 7.73092 18.0803C9.18112 18.3791 10.708 18.4153 12.1731 18.1781C12.8955 18.0612 13.6057 17.8824 14.2485 17.5721C14.8289 17.292 15.5398 16.8972 16.0173 16.4549C16.4941 16.0133 16.9906 15.2906 17.3431 14.7256C17.6453 14.2412 17.4991 13.6468 17.0211 13.2859C16.4901 12.8849 15.7021 12.885 15.1712 13.286L13.6652 14.4237C13.0815 14.8646 12.444 15.2705 11.6845 15.3916C11.5932 15.4062 11.4975 15.4194 11.3976 15.431M11.3976 15.431C11.3676 15.4345 11.3371 15.4378 11.3063 15.441M11.3976 15.431C11.5191 15.405 11.6398 15.33 11.7529 15.2312C12.289 14.7634 12.3229 13.975 11.8578 13.4526C11.7499 13.3314 11.6236 13.2303 11.4832 13.1466C9.15209 11.7562 5.52516 12.8152 3.33398 14.3691M11.3976 15.431C11.3672 15.4375 11.3367 15.441 11.3063 15.441M11.3063 15.441C10.8702 15.4857 10.36 15.4973 9.79379 15.4439" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default SystemAccounts;