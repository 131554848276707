import { FormHelperText, InputAdornment, TextField, TextFieldProps, useTheme } from "@mui/material";
import { useField } from "formik";
import { NumericFormat } from "react-number-format";

type Props = {
    name: string;
    label?: string;
    adornmentStart?: string;
    adornmentEnd?: string;
    decimalScale?: number;
    fixedDecimalScale?: boolean;
    disabled?: boolean;
    inputPropsSx?: object;
} & TextFieldProps;

const FormNumericFieldNew = ({ name, label, adornmentStart, adornmentEnd, decimalScale, fixedDecimalScale = false, disabled = false, inputPropsSx, ...otherProps }: Props) => {
    const theme = useTheme();

    const [field, meta, helpers] = useField(name);

    const configTextfield = {
        ...field,
        ...otherProps,
    };
    if (meta && meta.touched && meta.error) {
        configTextfield.error = true;
        configTextfield.helperText = meta.error;
    }

    const handleChange = (event: { target: { value: number | null } }) => {
        helpers.setTouched(true, true);
        helpers.setValue(event.target.value);
    };

    const inputProps = {
        ...(adornmentStart && {
            startAdornment: (
                <InputAdornment position="start">
                    {adornmentStart}
                </InputAdornment>
            ),
        }),
        ...(adornmentEnd && {
            endAdornment: (
                <InputAdornment position="end">
                    {adornmentEnd}
                </InputAdornment>
            ),
        }),
        ...(inputPropsSx && {
            sx: inputPropsSx,
        }),
    };

    return (
        <>
            <NumericFormat
                fullWidth
                label={label ? label : ''}
                value={field.value}
                customInput={TextField}
                disabled={disabled}
                displayType={'input'}
                fixedDecimalScale={fixedDecimalScale}
                decimalScale={decimalScale}
                thousandsGroupStyle='thousand'
                thousandSeparator={true}
                InputProps={inputProps}
                sx={{
                    ...(meta.error && meta.touched && {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.error.main,
                        },
                    }),
                }}
                onValueChange={(values) => {
                    const numericValue = parseFloat(values.value);
                    handleChange({
                        target: {
                            value: isNaN(numericValue) ? null : numericValue,
                        },
                    });
                }}
            />
            {meta.error && meta.touched && (
                <FormHelperText error sx={{ mx: '14px' }}>
                    {meta.error}
                </FormHelperText>
            )}
        </>
    );
};

export default FormNumericFieldNew;