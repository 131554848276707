import * as React from "react";
import { SvgIcon } from '@mui/material';

const FlagReport = (props:any) => (
  <SvgIcon {...props}>
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 22v-8m0 0V3m0 11h4M3 3V2m0 1h12a2 2 0 0 1 2 2v2m0 0h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2v-3m10-7v5a2 2 0 0 1-2 2H7"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);
export default FlagReport;
