import * as React from "react";
import { SvgIcon } from '@mui/material';

const SystemTransfers = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M18.333 9.167c-.008-2.596-.09-3.972-.976-4.857-.976-.977-2.548-.977-5.69-.977H8.333c-3.142 0-4.714 0-5.69.977-.976.976-.976 2.547-.976 5.69 0 3.143 0 4.714.976 5.69.976.977 2.548.977 5.69.977h1.25"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
            <path
                opacity={0.5}
                d="M16.667 16.667v-5m0 0l1.666 1.666m-1.666-1.666L15 13.333"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.917 11.667v5m0 0L14.583 15m-1.666 1.667L11.25 15"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                opacity={0.5}
                d="M8.333 13.333H5"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
            <path
                opacity={0.4}
                d="M1.667 8.333h16.666"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
            />
        </svg>
    </SvgIcon>
);
export default SystemTransfers;