import { Theme } from "@mui/material";
import { CSSProperties } from "react";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';
import ApprovalIcon from '@mui/icons-material/Approval';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


const successStatus = [
    'COMPLETED',
    'ACTIVE',
    'APPROVED',
    'APPROVED_BY_RISK_MANAGER',
    'APPROVED_BY_COMPLIANCE_MANAGER',
    'PAID',
    'VERIFIED',
    'USED',
    'PRIMARY',
    'SENT',
    'MANUALLY_PAID',
];

const warningStatus = [
    'FX_PENDING',
    'PENDING',
    'PENDING_FX',
    'INACTIVE',
    'ORDERED',
    'CARD_BLOCKED',
    'CARD_LOST',
    'PARTIAL',
    'PARTIALLY_PAID',
    'SUBMITTED',
    'APPROVAL',
    'LEGAL_APPROVAL',
    'TERMINATED',
    'PENDING_EXTERNALLY',
    'PLANNED',
    'UNVERIFIED',
    'EXPIRED',
    'UNKNOWN',
    'MATCHED',
    'IN_REVIEW',
    'IN REVIEW',
    'DONE',
    'CREATED',
    'SKIP_KYC',
    'UPCOMING',
    'OFFER',
    'COMPLETED_WARNING',
    'PARTIALLY_IMPORTED',
    'PARTIALLY_PAID',
];

const greyStatus = ['PENDING_UNASSIGNED_PAYMENT', 'DRAFT', 'NOT_PAID', 'NEW']

const errorStatus = ['REJECTED', 'ERROR', 'FAILED', 'DISABLED', 'BLOCKED', 'LATE', 'CANCELLED'];

const blueStatus = ['CANCELED', 'REVERTED', 'SUSPENDED', 'LOST', 'STOLEN', 'EXECUTED', 'ISSUED'];

export const getStatusColor = (status: string, theme: Theme, isText?: boolean, withBackground: boolean = true) => {

    const result: CSSProperties = {
        backgroundColor: theme.palette.success.main,
        color: isText ? theme.palette.text.primary : 'white'
    };


    if (successStatus.includes(status)) {
        result.backgroundColor = theme.palette.success.light;
        result.color = theme.palette.success.main;
    }

    else if (warningStatus.includes(status)) {
        result.backgroundColor = theme.palette.warning.light;
        result.color = theme.palette.warning.main;
    }

    else if (errorStatus.includes(status)) {
        result.backgroundColor = theme.palette.error.light;
        result.color = theme.palette.error.main;
    }

    else if (blueStatus.includes(status)) {
        result.backgroundColor = theme.palette.ligthBlue;
        result.color = theme.palette.blue;
    }

    else if (greyStatus.includes(status)) {
        result.backgroundColor = theme.palette.transparentGreen;
        result.color = theme.palette.text.primary;
    }

    else {

        if (status.includes('ACTIVE') && !status.includes('INACTIVE')) {
            result.backgroundColor = theme.palette.warning.main;
            if (isText) result.color = theme.palette.warning.main;
        }
        else if (status.includes('BLOCKED')) {
            result.backgroundColor = theme.palette.error.main;
            if (isText) result.color = theme.palette.error.main;
        }
        else {
            result.backgroundColor = theme.palette.transparentBlack;
            result.color = theme.palette.text.primary;

        }

    }

    if (!withBackground) {
        result.backgroundColor = undefined;
    }

    return result;
};

/* NEW, PENDING, PENDING_EXTERNALLY, PENDING_FX, APPROVAL, EMAIL_APPROVAL, LEGAL_APPROVAL, 
LEGAL_APPROVED, APPROVED, APPROVED_BY_RISK_MANAGER, APPROVED_BY_COMPLIANCE_MANAGER, REJECTED, 
COMPLETED, CANCELED, ERROR, FAILED, REVERTED   */

const documentSuccessStatus = ['COMPLETED', 'REJECTED', 'REVERTED'];
const documentApprovalStatus = ['LEGAL_APPROVAL', 'EMAIL_APPROVAL', 'APPROVAL', 'PENDING_FX'];
const documentPendingStatus = ['LEGAL_APPROVED', 'APPROVED', 'APPROVED_BY_RISK_MANAGER', 'APPROVED_BY_COMPLIANCE_MANAGER', 'NEW', 'PENDING', 'PENDING_EXTERNALLY'];
const documentErrorStatus = ['FAILED', 'ERROR', 'CANCELED', 'CANCELLED'];

export const getShortenerStatusIcon = (status: string, theme: Theme,) => {
    if (documentErrorStatus.includes(status)) {
        return <ErrorOutlineIcon sx={{ color: theme.palette.error.main }} />
    } else if (documentSuccessStatus.includes(status)) {
        return <CheckIcon sx={{ color: theme.palette.success.main }} />
    } else if (documentApprovalStatus.includes(status)) {
        return <ApprovalIcon sx={{ color: theme.palette.warning.main }} />
    } else if (documentPendingStatus.includes(status)) {
        return <AccessTimeIcon sx={{ color: theme.palette.text.secondary }} />
    } else {
        return <QuestionMarkIcon sx={{ color: theme.palette.blue }} />
    }


}
