import { GridRenderCellParams } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import RejectApproveButtons from "@/components/buttons/RejectApproveButtons";
import StatusChip from "@/components/chips/StatusChip";

interface Props {
    params: GridRenderCellParams,
    withActions: boolean,
    onApprove: () => void
    onReject?: () => void,
    withRejectBnt?: boolean,
    rejectTitle?: string,
    approveTitle?: string,
}

const StatusActionsCell = ({ params, withActions = false, onReject, onApprove, withRejectBnt, rejectTitle, approveTitle }: Props) => {

    const val = params.value;

    if (!val) return <></>;

    if (val === "PENDING") {
        return (
            <Grid item>
                <StatusChip label={"PENDING_UNASSIGNED_PAYMENT"} />
            </Grid>
        )
    }

    return (
        <Grid container columnSpacing={1}>
            <Grid item>
                <StatusChip label={val} />
            </Grid>
            {withActions && (
                <Grid item>
                    <RejectApproveButtons onReject={onReject} onApprove={onApprove} withRejectBnt={withRejectBnt} rejectTitle={rejectTitle} approveTitle={approveTitle} />
                </Grid>
            )}
        </Grid>
    );
};

export default StatusActionsCell;