import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AvailableVaultsReponse, VaultStore, VaultAccountType, PaymentPlanType, VaultType } from './vaultTypes';
import { isEmpty } from 'lodash';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';


export const paginationParamsInit: PaginateParams = {
	filter: '',
	orderBy: 'date',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0
}

const initialState: VaultStore = {
	loading: false,
	list: [],
	count: 0,
	error: null,
	pagination: paginationParamsInit,
	filters: {
		[HeaderFilterType.STATUS]: [],
	},
	filtersSet: false
};

const slice = createSlice({
	name: 'vault',
	initialState,
	reducers: {
		setVaults(state, action: PayloadAction<Array<VaultAccountType>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setPagination: (state, action: PayloadAction<PaginateParams>) => {
			state.pagination = action.payload;
		},
		setFilters: (state, action: PayloadAction<{types: VaultType[] }>) => {
			const statusOptions = [
				{
					value: 'ACTIVE',
					text: 'Pending',
					enabled: true,
				},
				{
					value: 'TERMINATED',
					text: 'Completed',
					enabled: true,
				}
			]

			const typesOptions = action.payload.types.map(item => ({
                value: item.vaultName,
                text: item.vaultName,
                enabled: true
            }));

			state.filters[HeaderFilterType.STATUS] = statusOptions;
			state.filters[HeaderFilterType.TYPE] = typesOptions;
			state.filtersSet = true;

		},
		setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, options: Array<FilterOptionType> }>) => {
			state.filters[action.payload.filterType] = action.payload.options;
		},
		setFiltersSet(state, action: PayloadAction<{ set: boolean }>) {
			state.filtersSet = action.payload.set;
		}
	}
});

export const { setVaults,
	setLoading,
	setError,
	setCount,
	setPagination, setFilters, setFilter, setFiltersSet } = slice.actions;


export const getVaultAccounts = (userId: number, payload: PaginateParams, proc?: string) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/console/vaults/accounts', {
				params: {
					userId: userId,
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
					...(!isEmpty(proc) && { proc: proc })
				}
			});
			const { data } = response;
			dispatch(setVaults(data.list));
			dispatch(setCount(data.count));
			dispatch(setPagination(payload));
			return data;
		} catch (e) {
			showErrorNotification(e);

		}
		finally { dispatch(setLoading(false)); }
	};
};

export const getAvailableVaults = async (userId: number): Promise<AvailableVaultsReponse> => {
	const response = await request.get('/api/console/vaults/user/list', {
		params: {
			userId: userId,
			orderBy: 'vaultName',
			sort: SortDirectionEnum.ASC,
		}
	});
	const { data } = response;
	return data;
};

export const getPaymentPlans = async (accountId: number): Promise<PaymentPlanType[]> => {
	const response = await request.get(`/api/console/vaults/paymentplan/${accountId}`);
	const { data } = response;
	return data;
};


export const getVaultsCurrencies = async (): Promise<string[]> => {
	const response = await request.get('/api/console/vaults/currencies');
	const { data } = response;
	return data;
};


export const getVaultsDeposits = async (ccy: string): Promise<any> => {
	const response = await request.get(`/api/console/vaults/deposits/${ccy}`);
	const { data } = response;
	return data;
};

export const getVaultsLiabilities = async (ccy: string, period: string): Promise<any> => {
	const response = await request.get(`/api/console/vaults/liabilities/${ccy}/${period}`);
	const { data } = response;
	return data;
};

export const downloadOverviewFile = async (
	wlpId: string,
	name: string
  ) => {
	const response = await request.get(
	  `/api/console/vaults/overview/export/${wlpId}`,
	  {
		responseType: "blob",
		// params: {
		//   cacheBustTimestamp: Date.now(),
		// },
	  }
	);
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement("a");
	link.download = name;
	link.href = url;
	link.className = "hidden";
	document.body.appendChild(link);
  
	link.onclick = function () {
	  requestAnimationFrame(function () {
		URL.revokeObjectURL(url);
		setTimeout(() => link.remove(), 300);
	  });
	};
  
	link.click();
  };

export default slice.reducer;
