import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AccountTypeEnum } from '../accounts/accountsTypes';
import { TransfersStore, DocumentTypeEnum, DocumentStatusEnum, TrasferStoreKeyEnum, DocumentApproveRejectPaylod, DocumentTypeWBH, CryptoTradesEnum, DocumentRejectWithOptionsPaylod } from './transfersTypes';
import { t } from '@/utils/translate';
import { addFiles } from '@/utils/files';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { Wlp } from '../user/types';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { RejectionOptionResponse } from '../documentDetails/documentDetailsTypes';
import { FileScanStatus } from '../files/filesTypes';
import { isFileStatusValid } from '../files/filesSlice';


export const paginationParamsInit: PaginateParams = {
	filter: '',
	orderBy: 'date',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0
};

export const paginationParamsInitFiltered: PaginateParams = {
	filter: 'excludeDocumentTypes=CRYPTO_TRADE',
	orderBy: 'documentDate',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0
}

const initialState: TransfersStore = {
	[TrasferStoreKeyEnum.ALL]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInitFiltered,
		filters: {
			[HeaderFilterType.TYPE]: [],
			[HeaderFilterType.CURRENCY]: [],
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	},
	[TrasferStoreKeyEnum.BANK]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInitFiltered,
		filters: {
			[HeaderFilterType.TYPE]: [],
			[HeaderFilterType.CURRENCY]: [],
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	},
	[TrasferStoreKeyEnum.WALLET]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInitFiltered,
		filters: {
			[HeaderFilterType.TYPE]: [],
			[HeaderFilterType.CURRENCY]: [],
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	},
	[TrasferStoreKeyEnum.TRUST]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInitFiltered,
		filters: {
			[HeaderFilterType.TYPE]: [],
			[HeaderFilterType.CURRENCY]: [],
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	},
	[TrasferStoreKeyEnum.TOKEN]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInitFiltered,
		filters: {
			[HeaderFilterType.TYPE]: [],
			[HeaderFilterType.CURRENCY]: [],
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	},
	[TrasferStoreKeyEnum.CRYPTO_TRANSFER]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInitFiltered,
		filters: {
			[HeaderFilterType.TYPE]: [],
			[HeaderFilterType.CURRENCY]: [],
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	},
	[TrasferStoreKeyEnum.CRYPTO_TRADE]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInit,
		filters: {
			[HeaderFilterType.TYPE]: [],
			[HeaderFilterType.CURRENCY_FROM]: [],
			[HeaderFilterType.CURRENCY_TO]: [],
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	},
	[TrasferStoreKeyEnum.FLAGGED]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInit,
		filters: {
			[HeaderFilterType.TYPE]: [],
			[HeaderFilterType.CURRENCY]: [],
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	},
	[TrasferStoreKeyEnum.USER_FLAGGED]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInit,
		filters: {
			[HeaderFilterType.TYPE]: [],
			[HeaderFilterType.CURRENCY]: [],
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	}
};


const slice = createSlice({
	name: 'transfers',
	initialState,
	reducers: {
		setTransfers(state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, list: Array<DocumentTypeWBH> }>) {
			state[action.payload.storeKey].list = action.payload.list;
		},
		setLoading: (state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, loading: boolean }>) => {
			state[action.payload.storeKey].loading = action.payload.loading
		},
		setError: (state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, error: string }>) => {
			state[action.payload.storeKey].error = action.payload.error;
		},
		setCount: (state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, count: number }>) => {
			state[action.payload.storeKey].count = action.payload.count;
		},
		setPagination: (state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, pagination: PaginateParams }>) => {
			state[action.payload.storeKey].pagination = action.payload.pagination;
		},
		// Filters
		setFilters: (state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, wlps: Array<Wlp>, currencies: Array<FilterOptionType> }>) => {
			const wlpsOptions = action.payload.wlps.map(item => ({
				value: item.wlpId,
				text: getWlpName(item.wlpId),
				enabled: true
			}));
			const statusOptions = getDocumentStatusOptions();
			const typeOptions = getDocumentTypeOptions();
			if (action.payload.storeKey !== TrasferStoreKeyEnum.CRYPTO_TRADE) {
				state[action.payload.storeKey].filters[HeaderFilterType.CURRENCY] = action.payload.currencies;
				state[action.payload.storeKey].filters[HeaderFilterType.TYPE] = typeOptions;
			} else {
				state[action.payload.storeKey].filters[HeaderFilterType.CURRENCY_FROM] = action.payload.currencies;
				state[action.payload.storeKey].filters[HeaderFilterType.CURRENCY_TO] = action.payload.currencies;
			}
			state[action.payload.storeKey].filters[HeaderFilterType.STATUS] = statusOptions;
			state[action.payload.storeKey].filters[HeaderFilterType.WLPS] = wlpsOptions;
			state[action.payload.storeKey].filtersSet = true;
		},
		setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, storeKey: TrasferStoreKeyEnum, options: Array<FilterOptionType> }>) => {
			state[action.payload.storeKey].filters[action.payload.filterType] = action.payload.options;
		},
		setFiltersSet(state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, set: boolean }>) {
			state[action.payload.storeKey].filtersSet = action.payload.set;
		}
	}
});

export const { setLoading, setError, setCount, setPagination, setTransfers, setFilters, setFilter, setFiltersSet } = slice.actions;

export const getTransfers = (storeKey: TrasferStoreKeyEnum, payload: PaginateParams, version: "V1" | "V2" = 'V2', accountTypes?: Array<AccountTypeEnum>, documentTypes?: Array<DocumentTypeEnum | CryptoTradesEnum>, flagged?: boolean, userId?: number) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading({ storeKey, loading: true }));
			const data = await getTranferList(payload, false, version, accountTypes, documentTypes, flagged, userId);
			if (data) {
				dispatch(setTransfers({ storeKey, list: data.list }));
				dispatch(setCount({ storeKey, count: data.count }));
				dispatch(setPagination({ storeKey, pagination: payload }));
			}
			dispatch(setLoading({ storeKey, loading: false }));
		} catch (e: any) {
			if (e?.name && e.name !== 'CanceledError') {
				showErrorNotification(e);
				dispatch(setLoading({ storeKey, loading: false }));
			}
		}
	};
};

export const addDateFromIfNoRequiredFilters = (filterString: string): string => {
	const filters = filterString.split(';');

	const requiredFilters = ['any', 'type', 'currency', 'wlpId', 'status', 'userId', 'walletId'];

	const hasAnyRequiredFilter = requiredFilters.some(requiredFilter =>
		filters.some(filter => filter.startsWith(`${requiredFilter}=`))
	);

	if (!hasAnyRequiredFilter) {
		const currentDate = new Date();
		const sixMonthsAgo = new Date(currentDate);
		sixMonthsAgo.setMonth(currentDate.getMonth() - 5);

		// Format the date to yyyy-MM-dd
		const formatDate = (date: Date) =>
			date.toISOString().split('T')[0];

		const dateFrom = formatDate(sixMonthsAgo);
		filterString += filterString ? `;documentDateFrom=${dateFrom}` : `documentDateFrom=${dateFrom}`;
	}

	return filterString;
}

let abortController: AbortController | null = null;

export const getTranferList = async (
	payload: PaginateParams,
	assignedToMe: boolean = false,
	version: "V1" | "V2" = 'V2',
	accountTypes?: Array<AccountTypeEnum>,
	documentTypes?: Array<DocumentTypeEnum | CryptoTradesEnum>,
	flagged?: boolean,
	userId?: number,
): Promise<{ list: Array<DocumentTypeWBH>, count: number }> => {

	if (abortController) {
		abortController.abort();
	}

	abortController = new AbortController();
	const { signal } = abortController;

	const params = {
		filter: addDateFromIfNoRequiredFilters(payload.filter),
		orderBy: payload.orderBy,
		sort: payload.sort.toUpperCase(),
		skip: payload.skip.toString(),
		take: payload.take,
		version: version,
		assignedToMe: assignedToMe,
		...(accountTypes && { accountTypes: accountTypes.join(',') }),
		...(documentTypes && { documentTypes: documentTypes.join(',') }),
		...(flagged && { flagged: flagged }),
		...(userId && { userId: userId }),
		...(version === 'V2' && (payload.filter.includes('walletId=') || payload.filter.includes('userId=') ? { queryVersion: 'V5' } : { queryVersion: 'V2' })),
	}

	const response = await request.get(`/api/console/documents/list`, { params, signal });

	return response?.data || null;
};

export const getTransferDetails = async (id: number) => {
	const response = await request.get(`/api/console/documents/id/${id}`);
	return response?.data;
};

export const approveDocument = async (payload: DocumentApproveRejectPaylod) => {
	const formData = new FormData();
	formData.append("docId", `${payload.docId}`);
	formData.append("docUserId", `${payload.docUserId}`);
	formData.append("docNumber", payload.docNumber);
	formData.append("documentWlp", payload.documentWlp);
	formData.append("reason", payload.reason);
	addFiles(formData, payload.files);

	const response = await request.post('/api/console/documents/approve', formData, { headers: { "Content-Type": "multipart/form-data" } });
	return response?.data;
};

export const rejectDocument = async (payload: DocumentApproveRejectPaylod) => {
	const formData = new FormData();
	formData.append("docId", `${payload.docId}`);
	formData.append("docUserId", `${payload.docUserId}`);
	formData.append("docNumber", payload.docNumber);
	formData.append("documentWlp", payload.documentWlp);
	formData.append("reason", payload.reason);
	addFiles(formData, payload.files);
	const response = await request.post('/api/console/documents/reject', formData, { headers: { "Content-Type": "multipart/form-data" } });
	return response?.data;
};

export const rejectDocumentWithOptions = async (payload: DocumentRejectWithOptionsPaylod, options: Record<string, string | number>) => {
	const formData = new FormData();
	formData.append("docId", `${payload.docId}`);
	formData.append("docUserId", `${payload.docUserId}`);
	formData.append("docNumber", payload.docNumber);
	formData.append("documentWlp", payload.documentWlp);
	formData.append("options", JSON.stringify(options));

	if (payload.files) addFiles(formData, payload.files);
	const response = await request.post('/api/console/documents/reject-with-options', formData, { headers: { "Content-Type": "multipart/form-data" } });
	return response?.data;
};

export const getRejectOptions = async (docNumber: string, wlpId: string): Promise<RejectionOptionResponse> => {
	const response = await request.get(`/api/console/documents/reject/options/${docNumber}?wlpId=${wlpId}`);
	return response?.data;
}

export const downloadAttachment = async (key: string, name: string): Promise<FileScanStatus | undefined> => {
	const response = await request.get(`/api/console/documents/attachment/${key}?name=${name}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
			validateStatus: (status) => status < 500
		});

	const status = response.headers['x-file-status'];

	if (!isFileStatusValid(status)) return status;

	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = name;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};


export const getDocumentTypeOptions = (): Array<FilterOptionType> => {
	return Object.keys(DocumentTypeEnum).map(key => { return ({ value: key, text: t('enum.documentTypeEnum.' + key), enabled: true }); })
};

export const getDocumentSpecificTypeOptions = (allowedTypes: Array<string>): Array<FilterOptionType> => {
	return Object.keys(DocumentTypeEnum)
		.filter(key => allowedTypes.includes(key))
		.map(key => {
			return {
				value: key,
				text: t('enum.documentTypeEnum.' + key),
				enabled: true
			};
		});
};

export const getDocumentStatusOptions = (): Array<FilterOptionType> => {
	const excludedKeys = [
		DocumentStatusEnum.APPROVED_BY_RISK_MANAGER,
		DocumentStatusEnum.APPROVED_BY_COMPLIANCE_MANAGER,
		DocumentStatusEnum.LEGAL_APPROVED,
		DocumentStatusEnum.APPROVED
	];

	return (Object.keys(DocumentStatusEnum) as Array<keyof typeof DocumentStatusEnum>)
		.filter(key => !excludedKeys.includes(DocumentStatusEnum[key]))
		.map(key => {
			return {
				value: key,
				text: t('enum.documentStatusEnum.' + key),
				enabled: true
			};
		});
};


export default slice.reducer;
