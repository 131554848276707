import TypographyWithEllipsis from "@/components/TypographyWithEllipsis";
import { getIsCrypto } from "@/redux/currencies/currenciesSlice";
import { getCurrencyLogo, getCurrencySign, getIsFiat } from "@/utils/currency";
import { CardMedia, TypographyProps, useTheme } from "@mui/material";
import Decimal from "decimal.js";
import { NumericFormat } from "react-number-format";

export interface Props {
    value: number;
    currency: string;
    fontWeight?: number;
    withCcySign?: boolean;
    withColor?: boolean | { positive: string; negative: string; };
    color?: string;
    withDash?: boolean;
    decimalScale?: number;
    customFieldName?: string;
    valueIsNumericString?: boolean;
    withMinusSign?: boolean;
    withLogo?: boolean;
    logoSize?: string;
    textProps?: TypographyProps;
};

const calculateMinimumDecimalPlaces = (value: number, isFiat: boolean, defaultScaleForFiat: number = 2, maxScaleForCrypto: number = 22) => {
    if (!value) return { scale: 2, fixed: true }
    if (isFiat) {
        return { scale: defaultScaleForFiat, fixed: true };
    } else {
        const decimalValue = new Decimal(value);
        const actualDecimals = decimalValue.decimalPlaces();

        const scale = Math.max(2, Math.min(actualDecimals, maxScaleForCrypto));
        const fixed = actualDecimals <= 2;
        return { scale, fixed };
    }
};

const calculateMinimumDecimalPlacesForFixed = (value: number, decimalScale: number, maxScaleForCrypto: number = 22) => {
    if (!value) return { scale: 2, fixed: true }
    else {
        const scale = Math.max(2, Math.min(decimalScale, maxScaleForCrypto));
        const fixed = decimalScale <= 2;
        return { scale, fixed };
    }
};

// This one is new and must replace the AmountCell in some time
const AmountValueCell = ({
    value,
    currency,
    fontWeight,
    withCcySign = true,
    withColor,
    color,
    withDash = true,
    decimalScale,
    withMinusSign = true,
    withLogo = false,
    logoSize = '18px',
    textProps
}: Props) => {
    const theme = useTheme();

    const ccy = currency ? (withCcySign ? getCurrencySign(currency) : currency) : ((!value && value !== 0) ? getCurrencySign('EUR') : '');

    const computedColor = withColor === true
        ? (color || (value < 0 ? theme.palette.error.main : theme.palette.success.main))
        : (typeof withColor === 'object'
            ? (value < 0 ? withColor.negative : withColor.positive)
            : undefined);

    const isFiat = getIsFiat(currency);
    const isCrypto = getIsCrypto(currency);

    const { scale, fixed } = (decimalScale !== null && decimalScale !== undefined)
        ? calculateMinimumDecimalPlacesForFixed(value, decimalScale)
        : calculateMinimumDecimalPlaces(value, isFiat);

    return (
        <>
            {(withLogo && isCrypto && currency) &&
                <CardMedia
                    image={getCurrencyLogo(currency)}
                    component="img"
                    alt={currency}
                    sx={{ width: logoSize, height: logoSize, mr: 0.5 }}
                />}
            <TypographyWithEllipsis sx={{ fontWeight: fontWeight, color: computedColor }} {...textProps}>
                <NumericFormat
                    displayType={'text'}
                    decimalScale={scale}
                    fixedDecimalScale={fixed}
                    thousandsGroupStyle='thousand'
                    thousandSeparator={true}
                    value={Math.abs(value)}
                    allowNegative={false}
                    prefix={
                        withLogo && isCrypto && currency
                            ? `${withMinusSign && value < 0 ? '-' : ''}`
                            : `${ccy} ${withMinusSign && value < 0 ? '-' : ''}`
                    }
                />
            </TypographyWithEllipsis>
        </>
    )
}

export default AmountValueCell;
