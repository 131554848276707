import FormTextField from '@/components/form/FormTextField';
import DialogTitle from '@/components/DialogTitle';
import { Alert, Box, Button, DialogContent, Grid, List, Tab, Tabs, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { MathingSystemAccount, Payment, TransactionType } from "@/redux/emiFinanceBanking/emiFinanceBankingTypes";
import { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { getMatchingAccounts, matchUnassignedPayment } from '@/redux/emiFinanceBanking/emiFinanceBankingSlice';
import { getErrorMessage, showSuccessNotification } from '@/utils/errors';
import TextWithAutocomplete from '@/components/form/TextWithAutocomplete';
import { convertDateFromEpoch, formatDateWithPattern } from '@/utils/date';
import { useSelector } from '@/store/store';
import { getIsFiat } from '@/utils/currency';
import DialogWithStatus from '@/components/DialogWithStatus';
import FormPaperLarge from '@/components/FormPaperLarge';
import { sortBy } from 'lodash';
import ListDetailItem from './ListDetailItem';
import FormNumericFieldNew from '@/components/form/FormNumericFieldNew';
import DangerTriangle from '@/icons/DangerTriangle';

interface Props {
    payment: Payment,
    open: boolean,
    integration: string,
    onComplete: () => void,
    onClose: () => void,
    isCrypto: boolean
}

export enum TabEnum {
    USER = 'USER',
    SYSTEM = 'SYSTEM'
}
interface MathingPaymentsFormData {
    walletIdFrom: string;
    walletIdTo: string;
    amountFrom: number;
    amountTo: number;
    walletId: string;
    amount: number;
    details: string;
};

const MatchTransactionForm = ({ payment, open, integration, onClose, onComplete, isCrypto }: Props) => {

    const { t } = useTranslation();

    const { currenciesDecimals } = useSelector((state) => state.currencies);

    const decimalScaleFrom = currenciesDecimals?.find(ccy => ccy.ccy === payment.currencyCode)?.decimals
        ?? (getIsFiat(payment.currencyCode) ? 2 : 5);

    const decimalScaleTo = currenciesDecimals?.find(ccy => ccy.ccy === payment.toCurrencyCode)?.decimals
        ?? (getIsFiat(payment.currencyCode) ? 2 : 5);

    const [currentTab, setCurrentTab] = useState(TabEnum.USER);
    const [systemAccounts, setSystemAccounts] = useState<Array<MathingSystemAccount>>([]);

    const [error, setError] = useState<string | null>(null);

    const getValidationSchema = (currentTab: TabEnum, transactionType: string) => {
        if (currentTab === TabEnum.USER && transactionType === TransactionType.TRADE) {
            return Yup.object({
                walletIdFrom: Yup.string().required(t('form.validator.required').toString()),
                walletIdTo: Yup.string().required(t('form.validator.required').toString()),
                amountFrom: Yup.number()
                    .typeError(t('form.validator.required').toString())
                    .required(t('form.validator.required').toString())
                    .moreThan(0, t('form.validator.positive').toString()),
                amountTo: Yup.number()
                    .typeError(t('form.validator.required').toString())
                    .required(t('form.validator.required').toString())
                    .moreThan(0, t('form.validator.positive').toString()),
            });
        } else {
            return Yup.object({
                walletId: Yup.string().required(t('form.validator.required').toString()),
                amount: Yup.number()
                    .typeError(t('form.validator.required').toString())
                    .required(t('form.validator.required').toString())
                    .moreThan(0, t('form.validator.positive').toString()),
                details: Yup.string().required(t('form.validator.required').toString()),
            });
        }
    };

    const validationSchema = getValidationSchema(currentTab, payment.transactionType);

    const submit = async (formData: MathingPaymentsFormData) => {
        try {
            const payload = {
                transactionId: payment.id,
                externalId: payment.externalId,
                matchingType: currentTab,
                details: currentTab === TabEnum.SYSTEM
                    ? (() => {
                        const [walletId, paymentConfigId] = formData.walletId.split(' ');
                        return [{
                            walletId: walletId,
                            amount: formData.amount,
                            currencyCode: payment.currencyCode,
                            paymentDetails: formData.details,
                            paymentConfigId: parseInt(paymentConfigId),
                        }];
                    })()
                    : payment.transactionType === TransactionType.TRADE
                        ? [{
                            walletIdFrom: formData.walletIdFrom,
                            walletIdTo: formData.walletIdTo,
                            amountFrom: formData.amountFrom,
                            amountTo: formData.amountTo,
                            currencyCode: payment.currencyCode,
                        }]
                        : [{
                            walletId: formData.walletId,
                            amount: formData.amount,
                            currencyCode: payment.currencyCode,
                            paymentDetails: formData.details,
                        }],
            };
            await matchUnassignedPayment(payload);
            showSuccessNotification(t('emiFinance.paymentMathced'));
            onComplete();
        }
        catch (e) {
            setError(getErrorMessage(e));
        }

    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: TabEnum) => {
        setError(null);
        setCurrentTab(newValue);
    };

    const getAccounts = useCallback(async () => {
        try {
            if (integration) {
                const data = await getMatchingAccounts(payment.currencyCode, integration, payment.transactionType);
                setSystemAccounts(data.filter(account => account.currencyCode === payment.currencyCode));
            }
        } catch (e) {
            setSystemAccounts([]);
        }
    }, [integration, payment.currencyCode, payment.transactionType]);

    useEffect(() => {
        getAccounts();
    }, [getAccounts]);

    const accountOptions = sortBy(systemAccounts.map((account) => {
        return {
            value: `${account.walletId} ${account.paymentConfigId}`,
            emi: account.emi,
            walletId: account.walletId,
            label: `${account.walletId} ${account.description}`,
            balance: account.balance,
            currency: account.currencyCode,
            description: account.description
        };
    }), ['description']);

    const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: MathingSystemAccount) => (
        <Box component="li" {...props}>
            <Grid container justifyContent='space-between'>
                {`${option.walletId} - ${option.description}`}
            </Grid>
        </Box>
    );

    const initialValues = {
        // Crypto trades
        walletIdFrom: '',
        walletIdTo: '',
        amountFrom: Math.abs(payment.amount),
        amountTo: Math.abs(payment.toAmount),
        // Others
        walletId: '',
        amount: Math.abs(payment.amount),
        details: payment.paymentDetails
    }

    const handleClose = () => {
        onClose();
        setError(null);
        setCurrentTab(TabEnum.USER);
    }

    return (
        <DialogWithStatus
            open={open}
            onClose={handleClose}
            fullWidth
            PaperComponent={FormPaperLarge}>
            <DialogTitle onClose={handleClose}>
                {t('form.buttons.match')}
            </DialogTitle>

            <DialogContent sx={{ pb: 0 }}>
                <Grid container direction='column' wrap='nowrap'>

                    <Grid item xs={1} width='100%' >
                        <Tabs value={currentTab}
                            onChange={handleTabChange}
                            centered>
                            <Tab value={TabEnum.USER}
                                label={t('form.fields.user')}
                                sx={{ width: '50%' }}
                            />
                            <Tab value={TabEnum.SYSTEM}
                                label={t('form.fields.system')}
                                sx={{ width: '50%' }}
                            />
                        </Tabs>
                    </Grid>

                    <Grid item pl='20px' mt={2} mb={1}
                        sx={(theme) => ({
                            borderRadius: '10px',
                            border: `1px solid ${theme.palette.transparentBlack}`,
                            backgroundColor: theme.palette.transparentBlack
                        })} >
                        <List dense={true}
                            sx={{
                                listStyleType: 'disc',
                                pl: 2,
                                '& .MuiListItem-root': {
                                    display: 'list-item',
                                    pl: 0,
                                },
                            }}>
                            <ListDetailItem
                                label={t('form.fields.transferId')}
                                value={payment.externalId}
                            />
                            {payment.transactionType !== TransactionType.TRADE && (
                                <ListDetailItem
                                    label={t('form.fields.amount')}
                                    value={Math.abs(payment.amount).toString()}
                                    currency={payment.currencyCode}
                                    isAmount
                                />
                            )}
                            <ListDetailItem
                                label={t('form.fields.date')}
                                value={formatDateWithPattern(convertDateFromEpoch(payment.transactionDate), 'dd.MM.yyyy HH:mm:ss')}
                            />
                            {!isCrypto && (
                                <ListDetailItem
                                    label={t('form.fields.name')}
                                    value={payment.transactionType === TransactionType.PAYMENT ? payment.payeeName : payment.payerName}
                                />
                            )}
                            {(((payment.transactionType === TransactionType.PAYMENT && !payment.payeeIban) ||
                                (payment.transactionType === TransactionType.DEPOSIT && !payment.payerIban)) &&
                                !crypto) ? (
                                <>
                                    <ListDetailItem
                                        label={t('form.fields.sortCode')}
                                        value={payment.transactionType === TransactionType.PAYMENT ? payment.payeeSortCode : payment.payerSortCode}
                                    />
                                    <ListDetailItem
                                        label={t('form.fields.accountNumber')}
                                        value={payment.transactionType === TransactionType.PAYMENT ? payment.payeeAccountNumber : payment.payerAccountNumber}
                                    />
                                </>
                            ) : isCrypto ? (
                                <>
                                    {payment.transactionType !== TransactionType.TRADE ? (
                                        <ListDetailItem
                                            label={t('form.fields.address')}
                                            value={payment.transactionType === TransactionType.WITHDRAW ? payment.payeeAddress : payment.payerAddress}
                                        />
                                    ) : (
                                        <>
                                            <ListDetailItem
                                                label={t('form.fields.from')}
                                                value={Math.abs(payment.amount)}
                                                currency={payment.currencyCode}
                                                isAmount
                                            />
                                            <ListDetailItem
                                                label={t('form.fields.to')}
                                                value={Math.abs(payment.toAmount)}
                                                currency={payment.toCurrencyCode}
                                                isAmount
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <ListDetailItem
                                    label={t('form.fields.account')}
                                    value={payment.transactionType === TransactionType.PAYMENT ? payment.payeeIban : payment.payerIban}
                                />
                            )}
                            <ListDetailItem
                                label={t('form.fields.paymentDetails')}
                                value={payment.paymentDetails}
                            />
                        </List>
                    </Grid>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={submit}
                        enableReinitialize>
                        {({ ...formik }) => {
                            return (
                                <Form>
                                    <Grid item xs={12} container alignContent='flex-start' rowGap={1}>
                                        {currentTab === TabEnum.USER ? (
                                            payment.transactionType === TransactionType.TRADE ? (
                                                <>
                                                    <Grid item xs={12}>
                                                        <FormTextField
                                                            name="walletIdFrom"
                                                            fullWidth
                                                            label={t('form.fields.fromWalletId').toString()}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormTextField
                                                            name="walletIdTo"
                                                            fullWidth
                                                            label={t('form.fields.toWalletId').toString()}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormNumericFieldNew
                                                            name="amountFrom"
                                                            decimalScale={decimalScaleFrom}
                                                            label={t('form.fields.fromAmount').toString()}
                                                            adornmentEnd={payment.currencyCode}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormNumericFieldNew
                                                            name="amountTo"
                                                            decimalScale={decimalScaleTo}
                                                            label={t('form.fields.toAmount').toString()}
                                                            adornmentEnd={payment.toCurrencyCode}
                                                        />
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid item xs={12}>
                                                        <FormTextField
                                                            name="walletId"
                                                            fullWidth
                                                            label={t('form.fields.assignToVirtualAccount')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormNumericFieldNew
                                                            name="amount"
                                                            decimalScale={decimalScaleFrom}
                                                            fullWidth
                                                            label={t('form.fields.amount').toString()}
                                                            adornmentEnd={payment.currencyCode}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormTextField
                                                            name="details"
                                                            fullWidth
                                                            label={t('form.fields.details').toString()}
                                                        />
                                                    </Grid>
                                                </>
                                            )
                                        ) : (
                                            <>
                                                <Grid item xs={12}>
                                                    <TextWithAutocomplete
                                                        options={accountOptions}
                                                        renderOption={renderOption}
                                                        name="walletId"
                                                        fullWidth
                                                        label={t('form.fields.assignToVirtualAccount')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormNumericFieldNew
                                                        name="amount"
                                                        decimalScale={decimalScaleFrom}
                                                        fullWidth
                                                        label={t('form.fields.amount').toString()}
                                                        adornmentEnd={payment.currencyCode}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormTextField
                                                        name="details"
                                                        fullWidth
                                                        label={t('form.fields.details').toString()}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        {error &&
                                            <Grid item xs={12} mt={0.5}>
                                                <Alert severity="info" icon={<DangerTriangle />}>
                                                    <Typography>{error}</Typography>
                                                </Alert>
                                            </Grid>
                                        }
                                    </Grid>

                                    <Grid container item justifyContent='space-between' mt='auto' pt={2} pb={2}>
                                        <Button onClick={handleClose} color='secondary' variant='outlined' >{t('form.buttons.cancel')}</Button>
                                        <Button type='submit'>{t('form.buttons.match')}</Button>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>

                </Grid>
            </DialogContent>
        </DialogWithStatus >
    );
}

export default MatchTransactionForm;