import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { EmiFinanceSystemStore, IntegrationBalanceType, Settlement } from "./emiFinanceSystemTypes";
import { EmiFinanceTabEnum, OverviewSubTabsReponse } from "../emiFinance/emiFinanceTypes";
import { getOverviewSubTabs } from "../emiFinance/emiFinanceSlice";
import { showErrorNotification } from "@/utils/errors";

const initialState: EmiFinanceSystemStore = {
    list: [],
    tabs: [],
    activeTab: '',
    loading: false
};

const slice = createSlice({
    name: 'emiFinanceSystem',
    initialState,
    reducers: {

        setFinanceSystemBalances(state, action: PayloadAction<Array<IntegrationBalanceType>>) {
            state.list = action.payload;
        },
        setSystemTabs(state, action: PayloadAction<Array<OverviewSubTabsReponse>>) {
            state.tabs = action.payload;
        },
        setActiveTab(state, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
    }
});

export const {
    setFinanceSystemBalances,
    setSystemTabs,
    setActiveTab,
    setLoading } = slice.actions;

export default slice.reducer;

let abortController: AbortController | null = null;

export const getSystemIntegrationBalancing = (product: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            if (abortController) {
                abortController.abort();
            }

            abortController = new AbortController();
            const { signal } = abortController;

            dispatch(setLoading(true));
            const response = await request.get(`/api/console/emi-finance/balancing/summary/${product}`, { signal });
            const data = response?.data || [];

            const filtered = data.map((item: IntegrationBalanceType) => ({
                ...item,
                loadingExternal: item.ownerKey === 'EXTERNAL',
                detailList: item.detailList.filter(d => d.isVisible !== 'N') || []
            }));

            if (filtered.some((item: IntegrationBalanceType) => item.ownerKey === 'EXTERNAL')) {
                const externalBalancingResponse = await getSystemBalancingExternalSummary(product);
                const externalBalancing = externalBalancingResponse.map((item: IntegrationBalanceType) => ({
                    ...item,
                    detailList: item.detailList.filter(d => d.isVisible !== 'N') || []
                }));

                const updatedWithExternal = filtered.map((item: IntegrationBalanceType) => {
                    if (item.ownerKey === 'EXTERNAL') {
                        const externalBalance = externalBalancing.find(
                            (externalItem: IntegrationBalanceType) =>
                                externalItem.currencyCode === item.currencyCode &&
                                externalItem.integration === item.integration
                        );
                        if (externalBalance) {
                            return {
                                ...item,
                                totalAmount: externalBalance.totalAmount,
                                loadingExternal: false,
                                detailList: [...item.detailList, ...externalBalance.detailList]
                            };
                        }
                    }
                    return item;
                });

                dispatch(setFinanceSystemBalances(updatedWithExternal));
            } else {
                dispatch(setFinanceSystemBalances(filtered));
            };
        } catch (e: any) {
            if (e?.name && e.name !== 'CanceledError') {
                showErrorNotification(e);
                dispatch(setFinanceSystemBalances([]));
            }
        } finally {
            dispatch(setLoading(false));
        }
    }
};

const getSystemBalancingExternalSummary = async (product: string): Promise<Array<IntegrationBalanceType>> => {
    const response = await request.get(`/api/console/emi-finance/balancing/external/summary/${product}`);
    return response?.data;
};

export const getSystemTabs = (viewType: EmiFinanceTabEnum) => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading(true));
            const response = await getOverviewSubTabs(viewType);
            dispatch(setActiveTab(response[0]?.viewType || ''));
            const data = response || [];
            dispatch(setSystemTabs(data));
        }
        catch (e) {
            dispatch(setSystemTabs([]));
        }
    }
};

export const getSystemBalancingBreakdown = async (type: string, integration: string, ownerKey: string): Promise<Array<IntegrationBalanceType>> => {
    const response = await request.get(`/api/console/emi-finance/balancing/breakdown/${type}/${integration}?ownerKey=${ownerKey}`);
    return response?.data;
};

export const getSettleList = async (integration: string, currency: string, ownerKey: string): Promise<Array<Settlement>> => {
    const response = await request.get(`/api/console/emi-finance/settlement/${integration}/${currency}?ownerKey=${ownerKey}`);
    return response?.data;
};

export const runSettleOperation = async (payload: any) => {
    const response = await request.post('/api/console/operations/settle', { data: payload });
    const { data } = response;
    return data;
};