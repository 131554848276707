
import CustomDataGrid from '@/components/dataGrid/CustomDataGrid';
import AmountWithLowerValueCell from '@/components/dataGrid/renderCell/AmountWithLowerValueCell';
import DateCell from '@/components/dataGrid/renderCell/DateCell';
import DocumentTypeCell from '@/components/dataGrid/renderCell/DocumentTypeCell';
import StatusCell from '@/components/dataGrid/renderCell/StatusCell';
import { getCardTopUpTransactions, paginationParamsInit, reset, setStoredUserId } from '@/redux/shCardTopupTransactions/shCardTopupTransactionsSlice';
import { CardTransactionsEnum, DocumentTypeEnum } from '@/redux/transfers/transfersTypes';
import { dispatch, useSelector } from '@/store/store';
import { PaginateParams } from '@/types/pagination';
import { t } from '@/utils/translate';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useMemo } from 'react';

interface Props {
    userId: number,
}

const getColumn = () => {
    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: t('form.fields.date'),
            flex: 1,
            renderCell: (params: GridRenderCellParams) => DateCell(params),
            sortable: false,
        },
        {
            field: 'activity',
            headerName: t('form.fields.type'),
            flex: 1,
            renderCell: DocumentTypeCell,
            valueGetter: (params) => {
                if (params.value === 'WITHDRAW') return CardTransactionsEnum.CARD_WITHDRAW;
                else if (params.value === 'PURCHASE') return CardTransactionsEnum.CARD_PURCHASE;
                else if (params.value === 'CARD_TOP_UP') return DocumentTypeEnum.CARD_TOPUP;
                else return params.value;
            },
            sortable: false,
        },
        {
            field: 'amount',
            headerName: t('form.fields.amount'),
            flex: 0.8,
            renderCell: (params: GridRenderCellParams) =>
                AmountWithLowerValueCell({
                    value: params.value,
                    currency: params.row.currency,
                    withLogo: true,
                    withMinusSign: false,
                }),
            sortable: false,
        },
        {
            field: 'status',
            headerName: t('form.fields.status'),
            flex: 0.8,
            sortable: false,
            renderCell: StatusCell
        }
    ];
    return columns;
};

const SHCardTopupTransactions = ({ userId }: Props) => {
    const { initial, list, error, count, loading, pagination, storedUserId } = useSelector((state) => state.shCardTopupTransactions);

    const fetch = async (searchParams: PaginateParams) => {
        await dispatch(getCardTopUpTransactions(userId, initial, searchParams))
    };

    useEffect(() => {
        if (storedUserId !== userId) {
            dispatch(reset());
            dispatch(setStoredUserId(userId));
        }
    }, [userId, storedUserId]);

    const columns = useMemo(() => {
        return getColumn()
    }, []);

    return (
        <>
            <CustomDataGrid
                title={t('cards.cardAccountTopUpTransactions')}
                rows={list}
                getRowId={(row) => row.transactionId}
                readyToFetchCondition={userId === storedUserId}
                rowCount={count}
                withRefreshBtn={true}
                fetch={fetch}
                loading={loading}
                paginationParams={pagination}
                paginationParamsInit={paginationParamsInit}
                columns={columns}
                loadingError={error}
                withSearch={false}
                searchFilterName='details'
            />
        </>
    );
};

export default SHCardTopupTransactions;
