import Checkmark from "@/icons/Checkmark";
import { ButtonProps, IconButton, useTheme } from "@mui/material";

interface ConfirmButtonProps extends ButtonProps {
    isGrey?: boolean;
    icon?: JSX.Element;
};

const ConfirmButton: React.FC<ConfirmButtonProps> = ({ isGrey = false, icon, ...otherProps }) => {
    const theme = useTheme();
    const { sx: sxProps, color, ...others } = otherProps;

    const getColorStyles = () => {
        if (isGrey) {
            return {
                backgroundColor: theme.palette.grey[200],
                hoverColor: theme.palette.grey[200],
                iconColor: theme.palette.primary.dark,
            };
        }

        switch (color) {
            case 'success':
                return {
                    backgroundColor: theme.palette.success.light,
                    hoverColor: theme.palette.success.light,
                    iconColor: theme.palette.success.main,
                };
            case 'warning':
                return {
                    backgroundColor: theme.palette.warning.light,
                    hoverColor: theme.palette.warning.light,
                    iconColor: theme.palette.warning.main,
                };
            default:
                return {
                    backgroundColor: theme.palette.grey[200],
                    hoverColor: theme.palette.grey[200],
                    iconColor: theme.palette.primary.dark,
                };
        }
    };

    const { backgroundColor, hoverColor, iconColor } = getColorStyles();

    return (
        <IconButton
            size="small"
            aria-label="close"
            {...others}
            sx={{
                maxHeight: "24px",
                maxWidth: "24px",
                backgroundColor,
                "&:hover": {
                    backgroundColor: hoverColor,
                },
                '&.Mui-disabled': {
                    opacity: 0.4,
                    backgroundColor,
                },
                ...(sxProps && { ...sxProps })
            }}>
            {icon || <Checkmark color={iconColor} sx={{ fontSize: "1rem" }} />}
        </IconButton>
    );
}

export default ConfirmButton;
