import Accounts from "@/icons/menuIcons/Accounts";
import Billing from "@/icons/menuIcons/Billing";
import Cards from "@/icons/menuIcons/Cards";
import Compliance from "@/icons/menuIcons/Compliance";
import CryptoWallet from "@/icons/menuIcons/CryptoWallet";
import Earn from "@/icons/menuIcons/Earn";
import Home from "@/icons/menuIcons/Home";
import Merchants from "@/icons/menuIcons/Merchants";
import Money from "@/icons/menuIcons/Money";
import Platforms from "@/icons/menuIcons/Platforms";
import Settings from "@/icons/menuIcons/Settings";
import Support from "@/icons/menuIcons/Support";
import Tokens from "@/icons/menuIcons/Tokens";
import Trades from "@/icons/menuIcons/Trades";
import Transfers from "@/icons/menuIcons/Transfers";
import Users from "@/icons/menuIcons/Users";
import { EmiSection, EmiSectionType, Permission, PermissionType } from "@/redux/user/types";
import { NavItemType } from "@/types";
import { checkAtLeastOnePermission, checkPermission, checkSectionEnabled } from "@/utils/permissions";
import { TFunction } from "i18next";
import Report from "@/icons/menuIcons/Report";
import SystemAccounts from "@/icons/menuIcons/SystemAccounts";
import SystemTransfers from "@/icons/menuIcons/SystemTransfers";
import Invoices from "@/icons/menuIcons/Invoices";

export const enum EmiSectionEnum {
    FINANCIAL_MANAGEMENT = "FINANCIAL_MANAGEMENT",
    PLATFORM_AND_USER_MANAGEMENT = "PLATFORM_AND_USER_MANAGEMENT",
    ANALYTICS_AND_REPORTING = "ANALYTICS_AND_REPORTING",
    PRODUCT_AND_SERVICES = "PRODUCT_AND_SERVICES",
    FINANCES_AND_CONFIGURATION = "FINANCES_AND_CONFIGURATION",
    SUPPORT = "SUPPORT",
    SYSTEM = "SYSTEM",
};

export interface Section {
    type?: EmiSectionEnum,
    items: Array<NavItemType>
};

const getSettingsUrl = (permissions: Array<Permission>): string => {
    if (checkPermission(permissions, PermissionType.GENERAL_SETTINGS)) {
        return '/settings/general';
    }
    if (checkPermission(permissions, PermissionType.MAINTENANCE)) {
        return '/settings/maintenance';
    }
    if (checkPermission(permissions, PermissionType.SYSTEM_USERS)) {
        return '/settings/users';
    }
    if (checkPermission(permissions, PermissionType.ROLES)) {
        return '/settings/roles';
    }
    if (checkPermission(permissions, PermissionType.ACTIVITY_LOGS)) {
        return '/settings/audit-logs';
    }
    return '/not-permitted';
};

const getEmiSections = (permissions: Array<Permission>, t: TFunction, iconSize: number, viewBox: string, emiSections: Array<EmiSectionType>): Array<Section> => {
    const settingsRequiredPermissions = [PermissionType.GENERAL_SETTINGS, PermissionType.MAINTENANCE, PermissionType.ROLES, PermissionType.SYSTEM_USERS, PermissionType.ACTIVITY_LOGS];

    return [
        {
            items: [
                {
                    id: 'dashboard',
                    title: t('pages.dashboard'),
                    type: 'item',
                    url: '/dashboard',
                    icon: <Home sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.DASHBOARD)
                },
                {
                    id: 'settings',
                    title: t('pages.settings'),
                    type: 'item',
                    url: getSettingsUrl(permissions),
                    icon: <Settings sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkAtLeastOnePermission(permissions, settingsRequiredPermissions) && checkSectionEnabled(emiSections, EmiSection.SETTINGS)
                },
            ]
        },
        {
            type: EmiSectionEnum.FINANCIAL_MANAGEMENT,
            items: [
                {
                    id: 'finances',
                    title: t('pages.finances'),
                    type: 'item',
                    url: '/finances/overview',
                    icon: <Money sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.FINANCE) && checkSectionEnabled(emiSections, EmiSection.FINANCES)
                },
                {
                    id: 'payoutsAndInvoices',
                    title: t('pages.payoutsAndInvoices'),
                    type: 'item',
                    url: '/billing/invoices',
                    icon: <Billing sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.FINANCE) && checkSectionEnabled(emiSections, EmiSection.PAYOUTS_INVOICES)
                },
                {
                    id: 'transfers',
                    title: t('pages.transfers'),
                    type: 'item',
                    url: '/transfers/all',
                    icon: <Transfers sx={{ fontSize: iconSize }} />,
                    allowed: checkPermission(permissions, PermissionType.DOCUMENTS) && checkSectionEnabled(emiSections, EmiSection.TRANSFERS)
                },
                {
                    id: 'trades',
                    title: t('pages.trades'),
                    type: 'item',
                    url: '/trades',
                    icon: <Trades sx={{ fontSize: iconSize }} />,
                    allowed: checkPermission(permissions, PermissionType.DOCUMENTS) && checkSectionEnabled(emiSections, EmiSection.TRADES)
                },
            ]
        },
        {
            type: EmiSectionEnum.PLATFORM_AND_USER_MANAGEMENT,
            items: [
                {
                    id: 'users',
                    title: t('pages.users'),
                    type: 'item',
                    url: '/users/private',
                    icon: <Users sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.USERS) && checkSectionEnabled(emiSections, EmiSection.USERS)
                },
                {
                    id: 'accounts',
                    title: t('pages.accounts'),
                    type: 'item',
                    url: '/accounts/all',
                    icon: <Accounts sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.ACCOUNTS) && checkSectionEnabled(emiSections, EmiSection.ACCOUNTS)
                },
                {
                    id: 'cards',
                    title: t('pages.cards'),
                    type: 'item',
                    url: '/cards/physical',
                    icon: <Cards sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.CARDS) && checkSectionEnabled(emiSections, EmiSection.CARDS)
                },
                {
                    id: 'wallets',
                    title: t('pages.wallets'),
                    type: 'item',
                    url: '/wallets',
                    icon: <CryptoWallet sx={{ fontSize: iconSize }} />,
                    allowed: checkPermission(permissions, PermissionType.ACCOUNTS) && checkSectionEnabled(emiSections, EmiSection.WALLETS)
                }
            ]
        },
        {
            type: EmiSectionEnum.ANALYTICS_AND_REPORTING,
            items: [
                {
                    id: 'compliance',
                    title: t('pages.compliance'),
                    type: 'item',
                    url: '/compliance/overview',
                    icon: <Compliance sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.COMPLIANCE) && checkSectionEnabled(emiSections, EmiSection.COMPLIANCE)
                },
                // {
                //     id: 'analytics',
                //     title: t('pages.analytics'),
                //     type: 'item',
                //     url: '/analytics',
                //     icon: <Chart sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                //     allowed: checkPermission(permissions, PermissionType.ANALYTICS) && checkSectionEnabled(emiSections, EmiSection.ANALYTICS)
                // },
                {
                    id: 'reports',
                    title: t('pages.reports'),
                    type: 'item',
                    url: '/reports/all',
                    icon: <Report sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.REPORTS) && checkSectionEnabled(emiSections, EmiSection.REPORTS)
                }
            ]
        },
        {
            type: EmiSectionEnum.SYSTEM,
            items: [
                {
                    id: 'systemAccounts',
                    title: t('pages.systemAccounts'),
                    type: 'item',
                    url: '/system/accounts/banking',
                    icon: <SystemAccounts sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.SYSTEM) && checkSectionEnabled(emiSections, EmiSection.SYSTEM_ACCOUNTS)
                },
                {
                    id: 'systemTransfers',
                    title: t('pages.systemTransfers'),
                    type: 'item',
                    url: '/system/transfers',
                    icon: <SystemTransfers sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.SYSTEM) && checkSectionEnabled(emiSections, EmiSection.SYSTEM_TRANSFERS)
                }
            ]
        },
        {
            type: EmiSectionEnum.PRODUCT_AND_SERVICES,
            items: [
                {
                    id: 'platforms',
                    title: t('pages.platforms'),
                    type: 'item',
                    url: '/platforms/overview',
                    icon: <Platforms sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.PLATFORMS) && checkSectionEnabled(emiSections, EmiSection.PLATFORMS)
                },
                {
                    id: 'merchants',
                    title: t('pages.merchants'),
                    type: 'item',
                    url: '/merchants/stores',
                    icon: <Merchants sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.MERCHANT_STORES) && checkSectionEnabled(emiSections, EmiSection.MERCHANTS)
                },
                {
                    id: 'earn',
                    title: t('pages.earn'),
                    type: 'item',
                    url: '/earn/overview',
                    icon: <Earn sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.EARN) && checkSectionEnabled(emiSections, EmiSection.EARN)
                },
                {
                    id: 'tokens',
                    title: t('pages.tokens'),
                    type: 'item',
                    url: '/tokens/overview',
                    icon: <Tokens sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.TOKENS) && checkSectionEnabled(emiSections, EmiSection.TOKENS)
                },
                {
                    id: 'invoices',
                    title: t('pages.invoices'),
                    type: 'item',
                    url: '/invoices',
                    icon: <Invoices sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.INVOICES) && checkSectionEnabled(emiSections, EmiSection.INVOICES)
                }
            ]
        },
        {
            type: EmiSectionEnum.SUPPORT,
            items: [
                {
                    id: 'support',
                    title: t('pages.support'),
                    type: 'item',
                    url: '/support',
                    icon: <Support sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.SUPPORT) && checkSectionEnabled(emiSections, EmiSection.SUPPORT),
                },
            ]
        }
    ];
}

export default getEmiSections;