import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { Wlp } from '../user/types';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { StoreKeyEnum, SystemTransfersStore } from './systemTransfersTypes';
import { addDateFromIfNoRequiredFilters, getDocumentStatusOptions, getDocumentTypeOptions } from '../transfers/transfersSlice';

export const paginationParamsInit = {
	filter: '',
	orderBy: 'documentId',
	sort: SortDirectionEnum.DESC,
	take: 15,
	skip: 0
};



const initialState: SystemTransfersStore = {
	[StoreKeyEnum.MAIN]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInit,
		filters: {
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: [],
			[HeaderFilterType.CURRENCY]: []
		},
		filtersSet: false
	},
	[StoreKeyEnum.WALLET]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInit,
		filters: {
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: [],
			[HeaderFilterType.CURRENCY]: []
		},
		filtersSet: false,
		storedWalletId: null,
	}
};


const slice = createSlice({
	name: 'systemTransfers',
	initialState,
	reducers: {
		setTransfers(state, action: PayloadAction<{ storeKey: StoreKeyEnum, list: Array<any> }>) {
			state[action.payload.storeKey].list = action.payload.list;
		},
		setLoading: (state, action: PayloadAction<{ storeKey: StoreKeyEnum, loading: boolean }>) => {
			state[action.payload.storeKey].loading = action.payload.loading
		},
		setWalletId: (state, action: PayloadAction<{ storeKey: StoreKeyEnum, walletId: number | null }>) => {
			state[action.payload.storeKey].storedWalletId = action.payload.walletId
		},
		setError: (state, action: PayloadAction<{ storeKey: StoreKeyEnum, error: string }>) => {
			state[action.payload.storeKey].error = action.payload.error;
		},
		setCount: (state, action: PayloadAction<{ storeKey: StoreKeyEnum, count: number }>) => {
			state[action.payload.storeKey].count = action.payload.count;
		},
		setPagination: (state, action: PayloadAction<{ storeKey: StoreKeyEnum, pagination: PaginateParams }>) => {
			state[action.payload.storeKey].pagination = action.payload.pagination;
		},
		// Filters
		setFilters: (state, action: PayloadAction<{ storeKey: StoreKeyEnum, wlps: Array<Wlp>, currencies: Array<FilterOptionType> }>) => {
			const wlpsOptions = action.payload.wlps.map(item => ({
				value: item.wlpId,
				text: getWlpName(item.wlpId),
				enabled: true
			}));
			const statusOptions = getDocumentStatusOptions();
			const typeOptions = getDocumentTypeOptions();
			state[action.payload.storeKey].filters[HeaderFilterType.CURRENCY] = action.payload.currencies;
			state[action.payload.storeKey].filters[HeaderFilterType.TYPE] = typeOptions;
			state[action.payload.storeKey].filters[HeaderFilterType.STATUS] = statusOptions;
			state[action.payload.storeKey].filters[HeaderFilterType.WLPS] = wlpsOptions;
			state[action.payload.storeKey].filtersSet = true;
		},
		setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, storeKey: StoreKeyEnum, options: Array<FilterOptionType> }>) => {
			state[action.payload.storeKey].filters[action.payload.filterType] = action.payload.options;
		},
		setFiltersSet(state, action: PayloadAction<{ storeKey: StoreKeyEnum, set: boolean }>) {
			state[action.payload.storeKey].filtersSet = action.payload.set;
		}
	}
});

export const { setLoading, setError, setCount, setPagination, setTransfers, setFilters, setFilter, setFiltersSet, setWalletId } = slice.actions;

export const getSystemTransfers = (type: StoreKeyEnum, payload: PaginateParams, walletId: number | null = null) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading({ storeKey: type, loading: true }));
			const response = await request.get('/api/console/system/transfers/list', {
				params: {
					filter: !walletId ? addDateFromIfNoRequiredFilters(payload.filter) : payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
					...(type === StoreKeyEnum.WALLET && walletId && { accountId: walletId })
				}
			});
			const { data } = response;
			dispatch(setTransfers({ storeKey: type, list: data.list }));
			dispatch(setCount({ storeKey: type, count: data.count }));
			dispatch(setPagination({ storeKey: type, pagination: payload }));
			dispatch(setLoading({ storeKey: type, loading: false }));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading({ storeKey: type, loading: false }));
		}
	};
};


export const getSystemTransfer = async (documentId: number): Promise<any> => {
	const response = await request.get(`/api/console/system/transfers/details/${documentId}`);
	const { data } = response;
	return data;
};


export default slice.reducer;
