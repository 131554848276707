import ChevronLeft from "@/icons/ChevronLeft";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

interface Props {
    title: string,
    onClick?: () => void
    hideBackOption?: boolean;
}

const FormBack = ({ title, onClick, hideBackOption }: Props) => {
    if (hideBackOption) {
        return (
            <Typography variant="h2">
                {title}
            </Typography>
        );
    }

    return (
        <Stack direction="row" onClick={onClick} sx={{ cursor: onClick ? 'pointer' : 'auto' }}>
            {onClick && <ChevronLeft />}
            <Typography variant="h2" ml={1.3}>
                {title}
            </Typography>
        </Stack>
    );
};


export default FormBack;
