import { Grid } from "@mui/material";
import CustomGridPagination from "./CustomGridPagination";



export const CustomToolbar = ({ readyToFetchCondition }: { readyToFetchCondition: boolean }) => {
    return (
        <Grid container justifyContent="space-between" alignItems="center" pt={0.5}>
            <Grid item>
                {readyToFetchCondition && <CustomGridPagination />}
            </Grid>
        </Grid>
    );
};


export default CustomToolbar;
