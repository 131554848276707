import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { Card, CardReplacelPayload, CardShipmentCountry, CardsStore, CardStatusEnum, CardsTypeEnum, DectaCardTransactionsPayload, ProcessFeeType } from './cardsTypes';
import { t } from '@/utils/translate';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { Wlp } from '../user/types';
import { ProcessFeeEnum } from '../process/processTypes';
import { filter, isEmpty, sumBy } from 'lodash';
import { FeeCategory, FeeDetail } from '../userFees/userFeesTypes';


export const paginationParamsInit: PaginateParams = {
	filter: '',
	orderBy: 'cardCreationDate',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0
}

const initialState: CardsStore = {
	[CardsTypeEnum.PHYSICAL]: {
		loading: false,
		list: [],
		count: 0,
		error: null,
		pagination: paginationParamsInit,
		filters: {
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	},
	[CardsTypeEnum.VIRTUAL]: {
		loading: false,
		list: [],
		count: 0,
		error: null,
		pagination: paginationParamsInit,
		filters: {
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	},
	[CardsTypeEnum.DELIVERIES]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInit,
		filters: {
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	},
	[CardsTypeEnum.ALL]: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paginationParamsInit,
		filters: {
			[HeaderFilterType.WLPS]: [],
			[HeaderFilterType.STATUS]: []
		},
		filtersSet: false
	}
};


const slice = createSlice({
	name: 'cards',
	initialState,
	reducers: {
		setCards(state, action: PayloadAction<{ type: CardsTypeEnum, list: Array<Card> }>) {
			state[action.payload.type].list = action.payload.list;
		},
		setLoading: (state, action: PayloadAction<{ type: CardsTypeEnum, loading: boolean }>) => {
			state[action.payload.type].loading = action.payload.loading
		},
		setError: (state, action: PayloadAction<{ type: CardsTypeEnum, error: string }>) => {
			state[action.payload.type].error = action.payload.error;
		},
		setCount: (state, action: PayloadAction<{ type: CardsTypeEnum, count: number }>) => {
			state[action.payload.type].count = action.payload.count;
		},
		setPagination: (state, action: PayloadAction<{ type: CardsTypeEnum, pagination: PaginateParams }>) => {
			state[action.payload.type].pagination = action.payload.pagination;
		},
		setFilters: (state, action: PayloadAction<{ type: CardsTypeEnum, wlps: Array<Wlp> }>) => {
			const wlpsOptions = action.payload.wlps.map(item => ({
				value: item.wlpId,
				text: getWlpName(item.wlpId),
				enabled: true
			}));
			const statusOptions = getCardStatusOptions();
			state[action.payload.type].filters[HeaderFilterType.WLPS] = wlpsOptions;
			state[action.payload.type].filters[HeaderFilterType.STATUS] = statusOptions;
			state[action.payload.type].filtersSet = true;
		},
		setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, type: CardsTypeEnum, options: Array<FilterOptionType> }>) => {
			state[action.payload.type].filters[action.payload.filterType] = action.payload.options;
		},
		setFiltersSet(state, action: PayloadAction<{ type: CardsTypeEnum, set: boolean }>) {
			state[action.payload.type].filtersSet = action.payload.set;
		}
	}
});

export const {
	setCards,
	setLoading,
	setError,
	setCount,
	setPagination,
	setFilter,
	setFilters,
	setFiltersSet
} = slice.actions;

export const getCardList = (type: CardsTypeEnum, payload: PaginateParams) => {
	const typeFilter = (type === CardsTypeEnum.PHYSICAL || type === CardsTypeEnum.DELIVERIES) ? 'virtual=false;' : type === CardsTypeEnum.VIRTUAL ? 'virtual=true;' : '';
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading({ type, loading: true }));
			const response = await request.get('/api/console/cards/list', {
				params: {
					filter: typeFilter + payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setCards({ type, list: data.list }));
			dispatch(setCount({ type, count: data.count }));
			dispatch(setPagination({ type, pagination: payload }));
			dispatch(setLoading({ type, loading: false }));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading({ type, loading: false }));
		}
	};
};

export const getCard = async (cardId: number) => {
	const response = await request.get(`/api/console/cards/details/${cardId}`);
	const { data } = response;
	return data;
};


export const getCardStatusOptions = (): Array<FilterOptionType> => {
	return Object.keys(CardStatusEnum).map(key => { return ({ value: key, text: t('enum.cardStatusEnum.' + key), enabled: true }); })
};

export const getDectaCardTransactions = async (payload: DectaCardTransactionsPayload) => {
	const response = await request.post('/api/console/cards/transactions', payload);
	const { data } = response;
	return data;
};


export const getDectaCardTransactionsOnhold = async (accountId: number) => {
	const response = await request.post('/api/console/cards/transactions/holds', { accountId });
	const { data } = response;
	return data;
};

export const postBlockAndPulloutCard = async (cardId: number, userId: number, accountToId: number) => {
	const response = await request.post(`/api/console/cards/block/${cardId}`, { accountToId, userId });
	const { data } = response;
	return data;
};

export const postPulloutCard = async (cardId: number, userId: number, accountToId: number, amount: number, accountFromId: number) => {
	const response = await request.post(`/api/console/cards/pullout/${cardId}`, { accountToId, userId, amount, accountFromId });
	const { data } = response;
	return data;
};

export const postTopupCard = async (cardId: number, sourceWalletId: string, amount: number) => {
	const response = await request.post(`/api/console/cards/top-up/`, { cardId, sourceWalletId, amount });
	const { data } = response;
	return data;
};

export const postTopupAndCloseCard = async (cardId: number, sourceWalletId: string) => {
	const response = await request.post(`/api/console/cards/topup-and-close/${cardId}`, { sourceWalletId });
	const { data } = response;
	return data;
};

export const doActionWithCard = async (cardId: number, action: 'CARD_SUSPEND' | 'CARD_TOP_UP' | 'CARD_BLOCK' | 'CARD_PULL_OUT' | 'CARD_BLOCK_AND_PULL_OUT', payload: any) => {
	const response = await request.post(`/api/console/cards/action/${cardId}/${action}`, payload);
	const { data } = response;
	return data;
};

export const getDeliveryCountries = async (): Promise<CardShipmentCountry[]> => {
	const response = await request.get('/api/console/cards/delivery-countries');
	const { data } = response;
	return data;
};

export const getDeliveryMethods = async (integration: string, countryIso2: string, address: string) => {
	const response = await request.post('/api/console/cards/delivery-methods', { integration, country: countryIso2, address });
	const { data } = response;
	return data;
};

export const postRenewalCard = async (payload: CardReplacelPayload) => {
	const response = await request.post(`/api/console/cards/renewal`, payload);
	const { data } = response;
	return data;
};

export const sumMonthlyFees = (fees: FeeCategory): number => {
	return sumBy(
		Object.values(fees).flat(),
		(fee) => fee.type === ProcessFeeEnum.FIXED_MONTH ? fee.feeMonth : 0
	);
};

export const sumCreationFees = (fees: FeeCategory, ccy: string): number => {
	const replaceCardFees = (fees['REPLACE_CARD'] || []).filter(f => f.ccy === ccy)[0];
	return replaceCardFees.feeAmount || 0;
};

export const sumFees = (fees: ProcessFeeType[], amount: number, feeType?: ProcessFeeEnum): number => {
	const _fees = feeType ? filter(fees, { type: feeType }) : fees;
	if (isEmpty(_fees)) {
		return 0;
	}
	return sumBy(_fees, (fee) => getFeePerType(fee, amount));
};

const getFeePerType = (fee: FeeDetail, amount: number) => {

	let feeAmount: number;

	const minFee = fee?.minFee || 0;
	const minFeePerc = fee?.feePercent || 0;
	switch (fee.type) {
		case ProcessFeeEnum.FIXED:
			feeAmount = fee.feeAmount;
			break;
		case ProcessFeeEnum.FIXED_MONTH:
			feeAmount = fee.feeMonth;
			break;
		case ProcessFeeEnum.VOLUME:
			feeAmount = Math.max(minFee, amount * (minFeePerc / 100));
			break;
		case ProcessFeeEnum.PAYMENT:
			feeAmount = Math.max(minFee, amount * (minFeePerc / 100));
			break;
		default:
			feeAmount = 0;
	}
	return feeAmount;
};


export default slice.reducer;
